import request from '@/utils/request'

// 生产退料分页列表
export function backMaterialListAPI (params) {
  return request({
    url: '/api/system/produce/bill/return/material/list',
    method: 'get',
    params
  })
}
// 新增生产退料
export function backMaterialAddAPI (data) {
  return request({
    url: '/api/system/produce/bill/return/material/save',
    method: 'post',
    data
  })
}
//获取生产退料详情
export function backMaterialDetailAPI (billId) {
  return request({
    url: '/api/system/produce/bill/return/material/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 修改生产退料
export function backMaterialUpdateAPI (data) {
  return request({
    url: '/api/system/produce/bill/return/material/update',
    method: 'put',
    data
  })
}
// 更改状态
export function backMaterialUpdateStatusAPI (data) {
  return request({
    url: '/api/system/produce/bill/return/material/updateBillStatus',
    method: 'post',
    data
  })
}
