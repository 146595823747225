import request from '@/utils/request'

// 批量添加更新总仓库安全库存  新增
export function totalSaveOrUpdateAllAPI (data) {
  return request({
    url: '/api/system/store/all/saveOrUpdate',
    method: 'post',
    data
  })
}
//总仓库安全库存  详情
export function totalSafeStockListAPI (query) {
  return request({
    url: '/api/system/store/all/list',
    method: 'get',
    params: query
  })
}
//总仓库安全库存  删除
export function totalSafeStockRemoveAPI (data) {
  return request({
    url: '/api/system/store/all/remove',
    method: 'DELETE',
    data
  })
}
