import request from '@/utils/request'
//更新状态
export function updateReconStatus(params) {
  return request({
    url: params.url,
    method: 'post',
    data: {
      settlementTermIds: params.settlementTermIds,
    }
  })
}
//结算期限列表
export function listSettlementTerm(data) {
  return request({
    url: '/api/system/finance/settlement/term/listSettlementTerm',
    method: 'POST',
    data
  })
}
//添加结算期限列表
export function addSettlementTerm(data) {
  return request({
    url: '/api/system/finance/settlement/term/addSettlementTerm',
    method: 'POST',
    data
  })
}
//修改结算期限列表
export function modifySettlementTerm(data) {
  return request({
    url: '/api/system/finance/settlement/term/modifySettlementTerm',
    method: 'POST',
    data
  })
}
//获取结算期限列表详情
export function getSettlementTerm(params) {
  return request({
    url: '/api/system/finance/settlement/term/getSettlementTerm',
    method: 'get',
    params: params
  })
}