import request from '@/utils/request'

//更新门店盘点单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/shop/bill/inventory/surplus/updateBillStatus',
    method: 'post',
    data
  })
}

// 门店盘点单据分页列表
export function listOrder (query) {
  return request({
    url: '/api/system/shop/bill/inventory/surplus/list',
    method: 'get',
    params: query
  })
}

//获取门店盘点单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/shop/bill/inventory/surplus/getDetail?billId=${billId}`
  })
}

//新增门店盘点单据
export function addOrder (data) {
  return request({
    url: '/api/system/shop/bill/inventory/surplus/save',
    method: 'POST',
    data
  })
}

//修改门店盘点单据
export function updateOrder (data) {
  return request({
    url: '/api/system/shop/bill/inventory/surplus/update',
    method: 'PUT',
    data
  })
}

// 复制门店盘盈
export function copyShopInventoryOrder(data) {
  return request({
    url: '/api/system/shop/bill/inventory/surplus/copyShopInventoryOrder',
    method: 'POST',
    data
  })
}