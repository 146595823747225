import request from '@/utils/request'


// 商品每日库存查询
export function listBookStatisticsByStoreGoodsMonth (data) {
  return request({
    url: '/api/system/store/month/book/statistics/listBookStatisticsByStoreGoodsMonth',
    method: 'POST',
    data
  })
}

// 商品每日库存查询 合计
export function sumBookStatisticsByStoreGoodsMonth (data) {
  return request({
    url: '/api/system/store/month/book/statistics/sumListBookStatisticsByStoreGoodsMonth',
    method: 'POST',
    data
  })
}
// 商品每日库存查询
export function listMonthBookStatisticsByStoreGoods (data) {
  return request({
    url: '/api/system/store/month/book/statistics/listMonthBookStatisticsByStoreGoods',
    method: 'POST',
    data
  })
}

// 商品每日库存查询 合计
export function sumListMonthBookStatisticsByStoreGoods (data) {
  return request({
    url: '/api/system/store/month/book/statistics/sumListMonthBookStatisticsByStoreGoods',
    method: 'POST',
    data
  })
}
