import request from '@/utils/request'

// 门店收款对账(日期)
export function timeAPI(query) {
  return request({
    url: '/api/system/shop/report/pay/reconciliation/listDate',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店收款对账(汇总)
export function timeAPISummary(query) {
  return request({
    url: '/api/system/shop/report/pay/reconciliation/listDateSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店收款对账(门店)
export function shopAPI(query) {
  return request({
    url: '/api/system/shop/report/pay/reconciliation/listShop',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
// 门店收款对账(收银员)
export function cashierAPI(query) {
  return request({
    url: '/api/system/shop/report/pay/reconciliation/listCashier',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店收款对账收银员(汇总)
export function cashierAPISummary(query) {
  return request({
    url: '/api/system/shop/report/pay/reconciliation/listCashierSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
