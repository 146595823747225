import request from '@/utils/request'

//会员次卡购买查询 分页列表
export function vipMarketingTimesCardPurchase(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardPurchase',
    method: 'get',
    params
  })
}

//会员次卡购买查询 分页汇总
export function vipMarketingTimesCardPurchaseSummary(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardPurchaseSummary',
    method: 'get',
    params
  })
}