import request from '@/utils/request'

//厨打商品方案   分页列表
export function cookPrintListAPI (params) {
  return request({
    url: '/api/system/shop/cook/case/list',
    method: 'get',
    params
  })
}
// 厨打商品方案  新增
export function cookPrintAddAPI (data) {
  return request({
    url: '/api/system/shop/cook/case/save',
    method: 'post',
    data
  })
}
//厨打商品方案  详情
export function cookPrintDetailAPI (printCaseId) {
  return request({
    url: '/api/system/shop/cook/case/getDetail?printCaseId=' + printCaseId,
    method: 'get'
  })
}
// 厨打商品方案   修改
export function cookPrintUpdateAPI (data) {
  return request({
    url: '/api/system/shop/cook/case/update',
    method: 'put',
    data
  })
}
// 厨打商品方案  删除
export function cookPrintDeleteAPI (printCaseIds) {
  return request({
    url: '/api/system/shop/cook/case/remove',
    method: 'delete',
    data: printCaseIds
  })
}

// 厨打商品方案  启用/停用
export function cookPrintIsStopAPI (printCaseIds) {
  return request({
    url: '/api/system/shop/cook/case/isStop',
    method: 'post',
    data:printCaseIds
  })
}

// 厨打商品方案   复制
export function cookPrintCopyAPI (printCaseIds) {
  return request({
    url: '/api/system/shop/cook/case/copy',
    method: 'post',
    data: printCaseIds
  })
}
