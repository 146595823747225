import request from '@/utils/request'

// 生产部门管理分页列表
export function produceDeptList (data) {
  return request({
    url: '/api/system/produce/dept/list',
    method: 'POST',
    data
  })
}

// 新增生产部门管理
export function produceDeptAdd (data) {
  return request({
    url: '/api/system/produce/dept/save',
    method: 'post',
    data
  })
}

// 修改生产部门管理
export function produceDeptUpdate (data) {
  return request({
    url: '/api/system/produce/dept/update',
    method: 'put',
    data
  })
}

// 删除生产部门管理
export function produceDeptRemove (data) {
  return request({
    url: '/api/system/produce/dept/delete',
    method: 'delete',
    data
  })
}

// 获取生产部门管理详情
export function produceDeptDetail (produceDeptId) {
  return request({
    url: `/api/system/produce/dept/getDetail?produceDeptId=${produceDeptId}`,
    method: 'get'
  })
}

// 获取生产部门管理还原接口
export function produceDeptRestore (data) {
  return request({
    url: '/api/system/produce/dept/restore',
    method: 'post',
    data
  })
}

// 生产部门管理列表  全部
export function queryProduceBasDeptList (data) {
  return request({
    url: '/api/system/produce/dept/queryProduceBasDeptList',
    method: 'post',
    data
  })
}
