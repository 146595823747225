import request from '@/utils/request'

// 查询分店商品数据
export function getShopAndGoodsData (params) {
  return request({
    url: '/api/system/shop/base/goods/list',
    timeout: 0,
    method: 'get',
    params
  })
}
// 保存分店商品数据
export function saveOrUpdate (data) {
  return request({
    url: '/api/system/shop/base/goods/saveOrUpdate',
    method: 'post',
    data
  })
}

// 查询分店商品数据-new
// export function getShopAndGoodsList (query) {
//   return request({
//     url: '/api/system/shop/base/goods/list',
//     method: 'get',
//     params: query
//   })
// }
