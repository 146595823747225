import request from '@/utils/request'

//商品价格方案   分页列表
export function priceCaseListAPI (params) {
  return request({
    url: '/api/system/goods/case/list',
    method: 'get',
    params
  })
}
// 商品价格方案  新增
export function priceCaseAddAPI (data) {
  return request({
    url: '/api/system/goods/case/save',
    method: 'post',
    data
  })
}
//商品价格方案  详情
export function priceCaseDetailAPI (priceCaseId) {
  return request({
    url: '/api/system/goods/case/getDetail?priceCaseId=' + priceCaseId,
    method: 'get'
  })
}
// 商品价格方案   修改
export function priceCaseUpdateAPI (data) {
  return request({
    url: '/api/system/goods/case/update',
    method: 'put',
    data
  })
}
// 商品价格方案  删除
export function priceCaseDeleteAPI (priceCaseIds) {
  return request({
    url: '/api/system/goods/case/delete',
    method: 'delete',
    data: priceCaseIds
  })
}
//商品价格方案自动编号
export function getGoodsPriceCaseAutoNo () {
  return request({
    url: '/api/system/goods/case/getGoodsPriceCaseAutoNo',
    method: 'get'
  })
}

// 还原商品价格方案
export function restorePriceCase (data) {
  return request({
    url: '/api/system/goods/case/restorePriceCase',
    method: 'POST',
    data
  })
}
