import request from '@/utils/request'

// 用户列表
export function userList(params) {
  return request({
    url: "/api/system/user/listUser",
    params
  })
}

export function tenantUserList(params) {
  return request({
    url: "/api/system/user/listTenantUser",
    params
  })
}
//   用户状态修改
export function updateUserStatus(data) {
  return request({
    url: "/api/system/user/updateUserStatus",
    method: 'post',
    data
  })
}

//批量还原用户信息
export function restoreUser(data) {
  return request({
    url: "/api/system/user/restoreUser",
    method: 'post',
    data
  })
}

//删除用户信息
export function removeUser(data) {
  return request({
    url: "/api/system/user/remove",
    method: 'delete',
    data
  })
}
