import request from '@/utils/request'

//会员次卡消费查询 分页列表
export function vipMarketingTimesCardConsume(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardConsume',
    method: 'get',
    params
  })
}

//会员次卡消费查询 分页汇总
export function vipMarketingTimesCardConsumeSummary(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardConsumeSummary',
    method: 'get',
    params
  })
}