import request from '@/utils/request'

//获取业务参数配置公共参数   分页列表
export function publicListAPI (params) {
  return request({
    url: '/api/system/sysParam/getSysParamConfig',
    method: 'get',
    params
  })
}
// 添加/修改业务参数配置公共参数
export function publicAddUpdateAPI(data) {
  return request({
    url: '/api/system/sysParam/saveSysParamConfig',
    method: 'put',
    data: data
  })
}