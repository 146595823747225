import request from '@/utils/request'

// 查询生产领料明细列表
/* export function produceDetailAPI (data) {
  return request({
    url: '/api/system/produce/report/listPickingDetails',
    method: 'POST',
    data
  })
} */

export function produceDetailAPI (params) {
  return request({
    url: '/api/system/produce/report/listPickingDetails',
    method: 'get',
    params
  })
}
