import request from '@/utils/request'

const urlPrefix = ''
const urlAddress = {
  listStoreBookDetail:
    urlPrefix + '/api/system/store/report/book/listStoreBookDetail',
  listBillStatisticsReport:
    urlPrefix + '/api/system/store/report/book/listBillStatisticsReport', //商品出入库单据
  listStoreBook: urlPrefix + '/api/system/store/report/book/listStoreBook' //商品出入库单据
}

// 仓库商品库存账本列表
export function storeInventoryGoodsList(params) {
  return request({
    url: "/api/system/store/report/book/listStoreBook",
    method: 'get',
    params,
  })
}

// 商品出入库明细表合计
export function sumListStoreBookDetail(params) {
  return request({
    url: "/api/system/store/report/book/sumListStoreBookDetail",
    method: 'get',
    params,
  })
}

// 商品出入库单据合计
export function sumListBillStatisticsReport(params) {
  return request({
    url: "/api/system/store/report/book/sumListBillStatisticsReport",
    method: 'get',
    params,
  })
}

// 仓库商品库存账本明细
export function storeInventoryGoodsDetail(params) {
  return request({
    url: "/api/system/store/report/book/getStoreBookDetail",
    params
  })
}

export function httpListStoreBookDetail(params) {
  return request({
    url: urlAddress.listStoreBookDetail,
    method: 'get',
    params: params
  })
}

export function httpListBillStatisticsReport(params) {
  return request({
    url: urlAddress.listBillStatisticsReport,
    method: 'get',
    params: params
  })
}

export function httpListStoreBook(params) {
  return request({
    url: urlAddress.listStoreBook,
    method: 'get',
    params: params
  })
}
