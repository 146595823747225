import request from '@/utils/request'
//商品销售分析
export function shopSaleAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisGoods',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//商品销售分析 合计行
export function shopSaleSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisGoodsSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店销售分析(收银员)
export function saleCashierAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisCashier',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店销售分析(收银员)  合计行
export function saleCashierSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisCashierSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店销售分析(分店+日期)
export function saleShopDateAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisShopDate',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店销售分析(分店+日期)  合计行
export function saleShopDateSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisShopDateSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店销售分析明细
export function listSaleAnalyseAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisDetail',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店销售分析明细  合计行
export function listSaleAnalyseSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisDetailSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 类别汇总查询
export function categoryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisCategory',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 类别汇总查询
export function categorySummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisCategorySummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 日期汇总查询
export function dateAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisDate',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 日期汇总查询  合计行
export function dateSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisDateSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 时间段汇总查询
export function timeSlotAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisTimeSlot',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 时间段汇总查询  合计行
export function timeSlotSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisTimeSlotSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 按收银员+类别 汇总查询
export function listSaleAnalysisCashierCategory(params) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisCashierCategory',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 按收银员+类别 汇总查询汇总  合计行
export function listSaleAnalysisCashierCategorySummary(params) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listSaleAnalysisCashierCategorySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
