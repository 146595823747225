import request from '@/utils/request'

//订单管理设置分页列表
export function orderManageRightList (params) {
  return request({
    url: '/api/system/mall/right/list',
    method: 'GET',
    params
  })
}

//新增订单管理设置
export function saveOrUpdate (data) {
  return request({
    url: '/api/system/mall/right/saveOrUpdate',
    method: 'POST',
    data
  })
}
