import request from '@/utils/request'

// 查询商户基本信息列表
export function listTenant (query) {
  return request({
    url: '/api/system/saas/tenant/list',
    method: 'get',
    params: query
  })
}

// 查询商户基本信息详细
export function getTenant (tenantId) {
  return request({
    url: '/api/system/saas/tenant/' + tenantId,
    method: 'get'
  })
}

// 新增商户基本信息
export function addTenant (data) {
  return request({
    url: '/api/system/saas/tenant/saveTenant',
    method: 'post',
    data: data
  })
}

// 修改商户基本信息
export function updateTenant (data) {
  return request({
    url: '/api/system/saas/tenant/updateTenant',
    method: 'put',
    data: data
  })
}

// 删除商户基本信息
export function delTenant (tenantId) {
  return request({
    url: '/api/system/saas/tenant/' + tenantId,
    method: 'delete'
  })
}

// 商户状态修改
export function changeTenantStatus (tenantIds, status) {
  const data = {
    tenantIds,
    status
  }
  return request({
    url: '/api/system/saas/tenant/changeStatus',
    method: 'put',
    data: data
  })
}

// 还原被删除的产品
export const setProductRevert = (data) => {
  return request({
    url: '/api/system/tenant/product/revert',
    method: 'POST',
    data
  })
}

//获取我的商户列表
export function TenantListAPI () {
  return request({
    url: '/api/system/saas/tenant/getMyTenant',
    method: 'get'
  })
}

//创建商户
export function createTenantAPI (data) {
  return request({
    url: '/api/system/saas/tenant/createTenant',
    method: 'post',
    data
  })
}

//获取商户权限
export function TenantPermissionAPI (id) {
  return request({
    url: `/api/system/saas/tenant/getTenantAuthorize`,
    params: {
      versionId: id
    }
  })
}

//建议商户手机号是否唯一性
export function soleTelephoneAPI (telephone) {
  return request({
    url: `/api/system/employee/validationTelephone`,
    params: {
      telephone
    }
  })
}

//获取所有商户
export function AllTenantAPI () {
  return request({
    url: `/api/system/saas/tenant/getAllTenant`
  })
}

//申请加入商户
export function JoinTenantAPI (tenantNo) {
  return request({
    url: `/api/system/saas/tenant/applyToJoinTenant?tenantNo=${tenantNo}`
  })
}
//根据商户号获取商户信息
export function findByTenantNo (tenantNo) {
  return request({
    url: `/api/system/saas/tenant/findByTenantNo?tenantNo=${tenantNo}`
  })
}

//切换商户
export function switchTenant (params) {
    return request({
        url: '/api/system/user/switchTenant',
        method: 'get',
        params
    })
}
