
import request from '@/utils/request'

//附件管理

// 附件管理分页列表
export function fileList(params) {
    return request({
      url: '/api/system/tenant/file/list',
      params
    })
  }

// 附件删除
export function delFile(attachFileIds) {
    return request({
      url: '/api/system/tenant/file/remove',
      method:'DELETE',
      data: attachFileIds
    })
  }

// 新增附件
export function saveFile(data) {
  return request({
    url: '/api/system/tenant/file/save',
    method:'POST',
    data
  })
}