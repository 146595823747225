import request from '@/utils/request'

//营养物质列表
export function nutrientListAPI (params) {
  return request({
    url: '/api/system/goods/nutrient/queryGoodsNutrientList',
    method: 'get',
    params
  })
}


// 批量修改营养物质   新增修改同一个接口
export function updateNutrientAPI (data) {
  return request({
    url: '/api/system/goods/nutrient/updateGoodNutrientInfo',
    method: 'post',
    data
  })
}

// 价签  删除
export function deleteNutrientAPI (data) {
  return request({
    url: '/api/system/goods/nutrient/deleteGoodsNutrient',
    method: 'delete',
    data
  })
}