import request from '@/utils/request'

// 机器管理列表
export function listMachine (query) {
  return request({
    url: '/api/system/shop/base/machine/list',
    method: 'get',
    params: query
  })
}

// 查询机器管理详细
export function getMachineDetail (machineId) {
  return request({
    url: '/api/system/shop/base/machine/getDetail',
    method: 'get',
    params: { machineId }
  })
}

// 新增机器管理
export function addMachine (data) {
  return request({
    url: '/api/system/shop/base/machine/save',
    method: 'post',
    data: data
  })
}

// 修改机器管理
export function updateMachine (data) {
  return request({
    url: '/api/system/shop/base/machine/update',
    method: 'put',
    data: data
  })
}

// 删除机器管理
export function delMachine (machineIds) {
  return request({
    url: '/api/system/shop/base/machine/remove',
    method: 'delete',
    data: machineIds
  })
}

// 解锁分店机器
export function resetMachine (data) {
  return request({
    url: `/api/system/shop/base/machine/resetMachine`,
    method: 'POST',
    data
  })
}

// 获取单据号
export function getMachineBillNo () {
  return request({
    url: '/api/system/shop/base/machine/getMachineBillNo',
    method: 'POST'
  })
}

// 还原机器
export function restoreMachine (data) {
  return request({
    url: '/api/system/shop/base/machine/restoreMachine',
    method: 'POST',
    data
  })
}
