import request from '@/utils/request'

//短信签名   分页列表
export function signListAPI (params) {
  return request({
    url: '/api/system/marketing/sms/signature/list',
    method: 'get',
    params
  })
}
// 短信签名  新增
export function signAddAPI (data) {
  return request({
    url: '/api/system/marketing/sms/signature/save',
    method: 'post',
    data
  })
}
//短信签名  详情
export function signDetailAPI (templateId) {
  return request({
    url:
      '/api/system/marketing/sms/signature/getDetail?templateId=' + templateId,
    method: 'get'
  })
}
// 短信签名   修改
export function signUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/sms/signature/update',
    method: 'put',
    data
  })
}

// 短信签名  删除
export function signDeleteAPI (signatureIds) {
  return request({
    url: '/api/system/marketing/sms/signature/delete',
    method: 'put',
    data: { signatureIds }
  })
}

// 短信签名  设为默认
export function signDefaultAPI (signatureId) {
  return request({
    url: '/api/system/marketing/sms/signature/setDefault',
    method: 'put',
    data: { signatureId }
  })
}

// 短信签名  还原
export function restoreSignature (data) {
  return request({
    url: '/api/system/marketing/sms/signature/restoreSignature',
    method: 'post',
    data
  })
}
