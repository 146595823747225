import request from '@/utils/request'

//公共导出oss
export function ossExportAPI (url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

//公共导出key  oss
export function ossExportKeyAPI (url, params) {
  return request({
    url,
    method: 'get',
    params
  })
}
