import request from '@/utils/request'


// 平台短信历史分页列表
export function msgDetailList(params) {
    return request({
        url: '/api/system/tenant/msg/detail/list',
        method: 'get',
        params: params
    })
}
