import request from '@/utils/request'

// 产品列表
export function productListTree(query) {
  return request({
    url: '/api/system/tenant/product/listSaasProduct',
    method: 'get',
    params: query
  })
}
//其他产品列表
export function listOtherSaasProduct(params) {
  return request({
    url: '/api/system/tenant/product/listOtherSaasProduct',
    method: 'get',
    params
  })
}
// 产品菜单列表树
export function productMenuTree(productId) {
  return request({
    url:
      '/api/system/tenant/product/productMenuTreeSelect',
    method: 'post',
    data: {
      productId
    }
  })
}

// 获取产品父节点列表
export function productParentList() {
  return request({
    url: '/api/system/tenant/product/getProductParentId',
    method: 'post'
  })
}

//新增产品
export function addProduct(data) {
  return request({
    url: '/api/system/tenant/product/saveSaasProduct',
    method: 'post',
    data
  })
}

//产品详情
export function productDetail(productId) {
  return request({
    url: `/api/system/tenant/product/getDetail?productId=${productId}`
  })
}

// 获取上线产品详情（购买下单）
export function getOnlineProductDetail(productId) {
  return request({
    url: `/api/system/tenant/product/getOnlineProductDetail?productId=${productId}`
  })
}

//编辑产品
export function updateProduct(data) {
  return request({
    url: '/api/system/tenant/product/updateSaasProduct',
    method: 'post',
    data
  })
}

//删除产品
export function delProduct(data) {
  return request({
    url: '/api/system/tenant/product/remove',
    method: 'DELETE',
    data
  })
}

// 商户产品菜单权限同步
export function fillTenantProductMenu(data) {
  console.log('请求接口  = ', data)
  return request({
    url: '/api/system/product/menu/fillTenantProductMenu',
    method: 'put',
    data: data
  })
}

//产品是否上线
export function isOnlineProduct(productId) {
  return request({
    url: `/api/system/tenant/product/isOnline?productId=${productId}`,
    method: 'post'
  })
}

// 服务产品对比功能系统产品树
export function functionTree(productId) {
  return request({
    url: `/api/system/tenant/compare/functionTree?productId=${productId}`
  })
}

//新增服务产品对比功能
export function saveFunction(data) {
  return request({
    url: '/api/system/tenant/compare/saveFunction',
    method: 'post',
    data
  })
}

//还原被删除产品
export function revertProductAPI(data) {
  return request({
    url: '/api/system/tenant/product/revert',
    method: 'post',
    data
  })
}

// 选择所属产品(包括：系统产品和功能模块)
export function getBelongProduct(params) {
  return request({
    url: '/api/system/tenant/product/getBelongProduct',
    method: 'get',
    params
  })
}

// 产品自编号
export function getProductAutoNo() {
  return request({
    url: '/api/system/tenant/product/getProductAutoNo',
    method: 'get',
  })
}

