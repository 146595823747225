import request from '@/utils/request'

// 付款方式列表
export function listPaymode (query) {
  return request({
    url: '/api/system/shop/base/pay/list',
    method: 'get',
    params: query
  })
}

// 查询付款方式详细
export function getPaymodeDetail (payModeId) {
  return request({
    url: '/api/system/shop/base/pay/getDetail',
    method: 'get',
    params: { payModeId }
  })
}

// 新增付款方式
export function addPaymode (data) {
  return request({
    url: '/api/system/shop/base/pay/save',
    method: 'post',
    data: data
  })
}

// 修改付款方式
export function updatePaymode (data) {
  return request({
    url: '/api/system/shop/base/pay/update',
    method: 'put',
    data: data
  })
}

// 删除付款方式
export function delPaymode (payModeIds) {
  return request({
    url: '/api/system/shop/base/pay/remove',
    method: 'delete',
    data: payModeIds
  })
}
//批量保存
export function batchPaymode (data) {
  return request({
    url: '/api/system/shop/base/pay/batchSave',
    method: 'POST',
    data: data
  })
}

// 付款方式列表  后期改动  门店收款设置专用接口
export function listCollectionPaymode (query) {
  return request({
    url: '/api/system/shop/detail/list',
    method: 'get',
    params: query
  })
}

// 付款方式明细  后期改动  门店收款设置专用接口
export function detailsCollectionPaymode (data) {
  return request({
    url: '/api/system/shop/detail/saveOrUpdate',
    method: 'post',
    data
  })
}

// 还原付款方式
export function restoreShopPayMode (data) {
  return request({
    url: '/api/system/shop/base/pay/restoreShopPayMode',
    method: 'post',
    data
  })
}
