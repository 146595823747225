import request from '@/utils/request'

// 商户产品查看明细分页列表
export function listTenantProductBookDetail (params) {
  return request({
    url: '/api/system/tenant/product/listTenantProductBookDetail',
    method: 'get',
    params
  })
}

// 明细停用产品
export function stopUsingByProductBookDetail (data) {
  return request({
    url: '/api/system/tenant/product/stopUsingByProductBookDetail',
    method: 'post',
    data
  })
}

// 批量修改到期时间
export function batchUpdateValidEndTime (data) {
  return request({
    url: '/api/system/tenant/account/product/book/batchUpdateValidEndTime',
    method: 'post',
    data
  })
}
