import request from '@/utils/request'

// 新增系统安装包
export function packageConfigSave(data) {
  return request({
    url: '/api/system/tenant/packageConfig/save',
    method: 'post',
    data
  })
}

// 修改系统安装包
export function packageConfigUpdate(data) {
  return request({
    url: '/api/system/tenant/packageConfig/update',
    method: 'put',
    data
  })
}
// 系统安装包分页列表
export function packageConfigList(params) {
  return request({
    url: '/api/system/tenant/packageConfig/list',
    method: 'get',
    params
  })
}
// 门店安装包下载列表
export function shopBillPosList(params) {
  return request({
    url: '/api/system/shop/bill/pos/list',
    method: 'get',
    params
  })
}
// 删除系统安装包
export function packageConfigDelete(data) {
  return request({
    url: '/api/system/tenant/packageConfig/delete',
    method: 'PUT',
    data
  })
}

// 获取产品安装包详情
export function setupPackageDetail(setupPackageId) {
  return request({
    url:
      '/api/system/tenant/packageConfig/getDetail?setupPackageId=' +
      setupPackageId,
    method: 'get'
  })
}
