import request from '@/utils/request'

// 分店分页列表
export function listShopInfo (query) {
  return request({
    url: '/api/system/shop/base/info/list',
    method: 'get',
    params: query
  })
}

// 查询分店详细
export function getShopInfoDetail (shopId) {
  return request({
    url: '/api/system/shop/base/info/getDetail?shopId=' + shopId,
    method: 'get'
  })
}

// 新增分店
export function addShopInfo (data) {
  return request({
    url: '/api/system/shop/base/info/save',
    method: 'post',
    data: data
  })
}

// 修改分店
export function updateShopInfo (data) {
  return request({
    url: '/api/system/shop/base/info/update',
    method: 'put',
    data: data
  })
}

// 删除分店
export function delShopInfo (shopIds) {
  return request({
    url: '/api/system/shop/base/info/remove',
    method: 'delete',
    data: shopIds
  })
}

// 获取所有门店
export function getAllShopInfo (data) {
  return request({
    url: '/api/system/shop/base/info/getAllShopInfo',
    method: 'post',
    data: data
  })
}

// 获取机构树
export function getDeptTree (query) {
  return request({
    url: '/api/system/shop/base/info/getDeptTree',
    method: 'get',
    params: query
  })
}

// 还原门店
export function restoreShopInfo (data) {
  return request({
    url: '/api/system/shop/base/info/restoreShopInfo',
    method: 'post',
    data
  })
}
