import request from '@/utils/request'

// 批量添加更新仓库安全库存  新增
export function saveOrUpdateAPI (data) {
  return request({
    url: '/api/system/store/standard/saveOrUpdate',
    method: 'post',
    data
  })
}
//仓库安全库存  详情
export function safeStockDetailAPI (storeId) {
  return request({
    url: '/api/system/store/standard/list?storeId=' + storeId,
    method: 'get'
  })
}
//仓库安全库存  删除
export function safeStockRemoveAPI (data) {
  return request({
    url: '/api/system/store/standard/remove',
    method: 'DELETE',
    data
  })
}
