import request from '@/utils/request'

//会员营销会员升级   分页列表
export function upgradeListAPI (params) {
  return request({
    url: '/api/system/marketing/vip/upgrade/case/list',
    method: 'get',
    params
  })
}
//查询会员等级变动日志列表   分页列表
export function upgradelogList (params) {
  return request({
    url: '/api/system/vip/base/upgradelog/list',
    method: 'get',
    params
  })
}
// 会员营销会员升级  新增
export function upgradeAddAPI (data) {
  return request({
    url: '/api/system/marketing/vip/upgrade/case/save',
    method: 'post',
    data
  })
}
//会员营销会员升级  详情
export function upgradeDetailAPI (upgradeCaseId) {
  return request({
    url:
      '/api/system/marketing/vip/upgrade/case/getDetail?upgradeCaseId=' +
      upgradeCaseId,
    method: 'get'
  })
}
// 会员营销会员升级   修改
export function upgradeUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/vip/upgrade/case/update',
    method: 'put',
    data
  })
}
// 会员营销会员升级  删除
export function upgradeDeleteAPI (upgradeCaseIds) {
  return request({
    url: '/api/system/marketing/vip/upgrade/case/delete',
    method: 'PUT',
    data: { upgradeCaseIds }
  })
}

// 会员营销会员升级  启用/停用
export function upgradeIsStopAPI (data) {
  return request({
    url: '/api/system/marketing/vip/upgrade/case/isStop',
    method: 'PUT',
    data
  })
}

//会员营销会员升级   更改状态
export function upgradeUpdateStatusAPI (data) {
  return request({
    url: '/api/system/marketing/vip/upgrade/case/updateStatus',
    method: 'post',
    data
  })
}
