import request from '@/utils/request'

// 查询参数列表
export function timeList (params) {
  return request({
    url: '/api/system/api/invoke/time/list',
    method: 'get',
    params
  })
}
