import request from '@/utils/request'

//会员次卡汇总查询 分页列表
export function vipMarketingTimesCardSummary(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardSummary',
    method: 'get',
    params
  })
}

//会员次卡汇总查询 分页汇总
export function vipMarketingTimesCardSummarySummary(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardSummarySummary',
    method: 'get',
    params
  })
}