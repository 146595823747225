import request from '@/utils/request'

//短信购买   分页列表
export function buyListAPI (params) {
  return request({
    url: '/api/system/marketing/sms/buy/order/list',
    method: 'get',
    params
  })
}
// 短信购买  新增
export function buyAddAPI (data) {
  return request({
    url: '/api/system/marketing/sms/buy/order/save',
    method: 'post',
    data
  })
}
//短信购买  详情
export function buyDetailAPI (orderId) {
  return request({
    url: '/api/system/marketing/sms/buy/order/getDetail?orderId=' + orderId,
    method: 'get'
  })
}

//短信购买   数量
export function getBookUsableFillcount () {
  return request({
    url: '/api/system/marketing/sms/book/getBookUsableFillcount',
    method: 'get'
  })
}

//支付状态
export function getOrderState (ordNo) {
  return request({
    url: '/api/system/marketing/sms/buy/order/getOrderState?ordNo=' + ordNo,
    method: 'get'
  })
}
