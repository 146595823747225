import request from '@/utils/request'

// 查询支付订单流水列表
export function listOrder(query) {
  return request({
    url: '/api/system/product/order/list',
    method: 'get',
    params: query
  })
}


