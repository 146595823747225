import request from '@/utils/request'

//帐本记录   分页列表
export function recordListAPI (params) {
  return request({
    url: '/api/system/marketing/base/coupon/case/book/list',
    method: 'get',
    params
  })
}
export function listSummary (params) {
  return request({
    url: '/api/system/marketing/base/coupon/case/book/listSummary',
    method: 'get',
    params
  })
}
//帐本记录  详情
export function recordDetailAPI (couponId) {
  return request({
    url:
      '/api/system/marketing/base/coupon/case/book/getDetail?couponId=' +
      couponId,
    method: 'get'
  })
}

// 作废优惠券
export function cancelMarketingCouponCaseBook(data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/book/cancelMarketingCouponCaseBook',
    method: 'POST',
    data
  })
}

//优惠券导入
export function uploadFileCoupon (data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/book/listImport',
    method: 'POST',
    // timeout: 300000,
    data,
    config:{
      headers:{
        'Content-Type': 'multipart/form-data'
      }
    }
  })
}

//查询会员导入结果
export function queryGetImportState (stateKey) {
  return request({
    url: `/api/system/marketing/base/coupon/case/book/getImportState?stateKey=${stateKey}`
  })
}
