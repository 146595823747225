import request from '@/utils/request'


//保质期预警查询表
export const getWarranty = (params) => request({
    url: '/api/system/store/reportForms/warranty',
    method: 'get',
    params
})

export function listMonthSettle(data) {
  return request({
    url: '/api/system/store/month/settle/listMonthSettle',
    method: 'POST',
    data
  })
}
export function applyMonthSettle(data) {
  return request({
    url: '/api/system/store/month/settle/applyMonthSettle',
    method: 'POST',
    data
  })
}
