import request from '@/utils/request'

//获取厨打订单自动打印设置详情
 export function orderGetDetailAPI (printerId) {
  return request({
    url: '/api/system/shop/cook/config/getDetail?printerId=' + printerId,
    method: 'get',  
  })
};

// 厨打订单自动打印设置分页列表
export function orderListAPI (params) {
  return request({
    url: '/api/system/shop/cook/config/list',
    method: 'get',
    params
  })
}

// 删除厨打订单自动打印设置
export function delOrderInfo(printerIds) {
  return request({
    url:'/api/system/shop/cook/config/remove',
    method: 'delete',
    data: printerIds
  })
}

// 新增厨打订单自动打印设置
export function orderAddAPI (data) {
  return request({
    url: '/api/system/shop/cook/config/save',
    method: 'post',
    data
  })
}
// 修改厨打订单自动打印设置
export function updateOrder(data) {
  return request({
    url: "/api/system/shop/cook/config/update",
    method: "put",
    data: data,
  });
}


// 新增修改厨打订单自动打印设置
export function saveOrUpdateAPI (data) {
  return request({
    url: '/api/system/shop/cook/config/saveOrUpdate',
    method: 'post',
    data
  })
}