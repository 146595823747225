import request from '@/utils/request'

// 配方批量修改分页列表
export function batcheditListAPI (params) {
  return request({
    url: '/api/system/produce/bill/bom/instead/list',
    method: 'get',
    params
  })
}
// 新增配方批量修改
export function batcheditAddAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/instead/save',
    method: 'post',
    data
  })
}
//获取配方批量修改详情
export function batcheditDetailAPI (billId) {
  return request({
    url: '/api/system/produce/bill/bom/instead/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 修改配方批量修改
export function batcheditUpdateAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/instead/update',
    method: 'put',
    data
  })
}
// 更改状态
export function batcheditUpdateStatusAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/instead/updateStatus',
    method: 'post',
    data
  })
}
