import request from '@/utils/request'

//优惠券包   分页列表
export function packageListAPI (params) {
  return request({
    url: '/api/system/marketing/base/package/list',
    method: 'get',
    params
  })
}
// 优惠券包  新增
export function packageAddAPI (data) {
  return request({
    url: '/api/system/marketing/base/package/save',
    method: 'post',
    data
  })
}
//优惠券包  详情
export function packageDetailAPI (packageId) {
  return request({
    url: '/api/system/marketing/base/package/getDetail?packageId=' + packageId,
    method: 'get'
  })
}
// 优惠券包   修改
export function packageUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/base/package/update',
    method: 'put',
    data
  })
}
// 优惠券包  删除
export function packageDeleteAPI (packageIds) {
  return request({
    url: '/api/system/marketing/base/package/delete',
    method: 'delete',
    data: packageIds
  })
}

// 优惠券包  启用/停用
export function packageIsStopAPI (data) {
  return request({
    url: '/api/system/marketing/base/package/isStop',
    method: 'post',
    data
  })
}

// 优惠券包   复制
export function packageCopyAPI (packageIds) {
  return request({
    url: '/api/system/marketing/base/package/copyMarketingCouponPackage',
    method: 'post',
    data: packageIds
  })
}
