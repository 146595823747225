import request from '@/utils/request'

//获取平台支付配置详情
export function payConfigDetail(payModeId) {
    return request({
      url: `/api/system/tenant/pay/config/getDetail?payModeId=${payModeId}`,
    })
  }

//新增/修改平台支付配置
export function handlePayConfig(data) {
  return request({
    url: '/api/system/tenant/pay/config/saveOrUpdate',
    method:"POST",
    data
  })
}

//获取支付通道数据
export function payChannelList(payModeId) {
  return request({
    url: `/api/system/shop/channel/o2oMallPayDetail?payModeId=${payModeId}`,
  })
}