import request from '@/utils/request'

//期初应收列表
export function listInitialDataPartnerAr(data) {
  return request({
    url: '/api/system/finance/initial/partner/ar/listInitialDataPartnerAr',
    method: 'POST',
    data
  })
}
//添加期初应收列表
export function addInitialAr(data) {
  return request({
    url: '/api/system/finance/initial/partner/ar/addInitialAr',
    method: 'POST',
    data
  })
}
// 修改期初应收列表
export function modifyInitialAr(data) {
  return request({
    url: '/api/system/finance/initial/partner/ar/modifyInitialAr',
    method: 'POST',
    data
  })
}
// 修改期初应收状态
export function modifyBillStatusAr(data) {
  return request({
    url: '/api/system/finance/initial/partner/ar/modifyBillStatus',
    method: 'POST',
    data
  })
}
//详情期初应收列表
export function getInitialDataPartnerArByBillId(params) {
  return request({
    url: '/api/system/finance/initial/partner/ar/getInitialDataPartnerArByBillId',
    method: 'get',
    params: params
  })
}

//更新单据状态
export function updateArbillStatus(params) {
  return request({
    url: params.url,
    method: 'post',
    data: {
      billIds: params.billIds,
      billStatus: params.billStatus,
      billType: params.billType,
    }
  })
}



//期初应付出列表
export function listInitialDataPartnerAp(data) {
  return request({
    url: '/api/system/finance/initial/partner/ap/listInitialDataPartnerAp',
    method: 'POST',
    data
  })
}

//添加期初应付列表
export function addInitialAp(data) {
  return request({
    url: '/api/system/finance/initial/partner/ap/addInitialAp',
    method: 'POST',
    data
  })
}

// 修改期初应付列表
export function modifyInitialAp(data) {
  return request({
    url: '/api/system/finance/initial/partner/ap/modifyInitialAp',
    method: 'POST',
    data
  })
}

// 修改期初应付状态
export function modifyBillStatusAp(data) {
  return request({
    url: '/api/system/finance/initial/partner/ap/modifyBillStatus',
    method: 'POST',
    data
  })
}
//详情期初应付列表
export function getInitialDataPartnerApByBillId(params) {
  return request({
    url: '/api/system/finance/initial/partner/ap/getInitialDataPartnerApByBillId',
    method: 'get',
    params: params
  })
}


export function arInitialDataListAPI (params) {
  return request({
    url: '/api/system/goods/initial/data/partner/ar/list',
    method: 'get',
    params
  })
}
// 期初应收    批量保存
export function arInitialDataBatchSaveAPI (data) {
  return request({
    url: '/api/system/goods/initial/data/partner/ar/batchSave',
    method: 'post',
    data
  })
}
//期初应收  详情
export function arInitialDataDetailAPI (billId) {
  return request({
    url: '/api/system/goods/initial/data/partner/ar/getDetail?billId=' + billId,
    method: 'get'
  })
}
//期初应收   更改状态
export function arInitialDataUpdateStatusAPI (data) {
  return request({
    url: '/api/system/goods/initial/data/partner/ar/updateStatus',
    method: 'post',
    data
  })
}
//期初应收 删除
export function arInitialDataDeleteAPI (partnerIds) {
  return request({
    url: '/api/system/goods/initial/data/partner/ar/delete',
    method: 'put',
    data: { partnerIds }
  })
}
//期初应收   修改信息
export function arInitialDataUpdateAPI (data) {
  return request({
    url: '/api/system/goods/initial/data/partner/ar/update',
    method: 'put',
    data: data
  })
}
