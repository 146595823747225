import request from '@/utils/request'

//微信小程序草稿模板列表
export function TemplatedRaftList() {
    return request({
        url: '/api/system/applet/getTemplatedRaftList'
    })
}

//微信小程序模板列表
export function TemplateList() {
    return request({
        url: '/api/system/applet/getTemplateList'
    })
}

//草稿添加到模板库(生成模板)
export function addTemplate(data) {
    return request({
        url: '/api/system/applet/addToTemplate',
        method: 'POST',
        data
    })
}

//删除微信小程序模板
export function delTemplate(data) {
    return request({
        url: '/api/system/applet/deleteTemplate',
        method: 'post',
        data
    })
}

//添加默认模板
export function addDefaultTemplate(data) {
    return request({
        url: '/api/system/tenant/template/saveOrUpdate',
        method: 'post',
        data
    })
}

//模板集合
export function getTenantWxAppTemplateList(data) {
    return request({
        url: '/api/system/tenant/template/getTenantWxAppTemplateList',
        method: 'post',
        data
    })
}

// 设置用户隐私
export function setPrivacySetting(data) {
  return request({
    url: '/api/system/applet/setPrivacySetting',
    method: 'post',
    data
  })
}
