import request from '@/utils/request'

//门店整体概览
export function getWhole(params) {
  return request({
    url: '/api/system/shop/overview/getWhole',
    method: 'get',
    timeout: 30000,
    params
  })
}

//零售
export function getRetail(params) {
  return request({
    url: '/api/system/shop/overview/getRetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//订单
export function getShopOrder(params) {
  return request({
    url: '/api/system/shop/overview/getShopOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员
export function getShopVip(params) {
  return request({
    url: '/api/system/shop/overview/getShopVip',
    method: 'get',
    timeout: 30000,
    params
  })
}

//营业订单趋势图
export function getOrderTrend(params) {
  return request({
    url: '/api/system/shop/overview/getOrderTrend',
    method: 'get',
    timeout: 30000,
    params
  })
}

//收款营收统计
export function getRevenueStatistics(params) {
  return request({
    url: '/api/system/shop/overview/getRevenueStatistics',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员统计
export function getVipStatistics(params) {
  return request({
    url: '/api/system/shop/overview/getVipStatistics',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售排行
export function getShopSaleRanking(params) {
  return request({
    url: '/api/system/shop/overview/getShopSaleRanking',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售目标
export function saleTarget(params) {
  return request({
    url: '/api/system/shop/overview/saleTarget',
    method: 'get',
    timeout: 30000,
    params
  })
}
