import request from '@/utils/request'

// 列表
export const getTemplateList = (params) => request({ url: '/api/system/template/list', method: 'GET', params })

// 保存
export const saveTemplate = (data) => request({ url: '/api/system/template/save', method: 'POST', data })

// 删除
export const deleteTemplate = (data) => request({ url: `/api/system/template/remove`, method: 'DELETE', data })

// 详情
export const getDetailTemplate = (params) => request({ url: `/api/system/template/getDetail`, method: 'GET', params })

// 路由换取 菜单
export const getMenuInfo = (params) => request({ url: `/api/system/menu/getRouterPathByMenuId`, method: 'GET', params })

// 修改
export const updateTemplate = (data) => request({ url: `/api/system/template/update`, method: 'PUT', data })

// 设为默认
export const setDefaultTemplate = (data) => request({ url: `/api/system/template/setDefault`, method: 'POST', data })

// 是否启用
export const isStopTemplate = (data) => request({ url: `/api/system/template/isStop`, method: 'POST', data })

// 复制
export const copyTemplate = (data) => request({ url: `/api/system/template/copyPrintTemplates`, method: 'POST', data })

// 通过菜单Id获取对应实体Class
export const getMenuIdClass = (params) => request({ url: '/api/system/template/getMenuIdClass', method: 'GET', params })

// 去掉按钮的菜单树列表
export const getListMenuNotF = (params) => request({ url: '/api/system/menu/listNotF', method: 'GET', params })

// 去掉按钮的菜单树列表
export const getMenuIdList = (params) => request({ url: '/api/system/template/getMenuIdList', method: 'GET', params })

// 回收站还原
export const restorePrintTemplates = (data) => request({ url: '/api/system/template/restorePrintTemplates', method: 'POST', data })


/**直接打印 */
// 直接打印获取分页列表
export const getDirectList = (params) => request({ url: '/api/system/template/direct/list', method: 'GET', params })
// 新增打印模版
export const saveDirect = (data) => request({ url: '/api/system/template/direct/save', method: 'POST', data })
// 新增打印模版
export const updateDirect = (data) => request({ url: '/api/system/template/direct/update', method: 'PUT', data })






