import request from '@/utils/request'

//会员次卡 分页列表
export function timesCardListApi (params) {
  return request({
    url: '/api/system/marketing/times/card/list',
    method: 'get',
    params
  })
}

//会员次卡 新增次卡
export function timesCardSaveApi (data) {
  return request({
    url: '/api/system/marketing/times/card/save',
    method: 'post',
    data
  })
}

//会员次卡 删除次卡
export function timesCardRemoveApi (data) {
  return request({
    url: '/api/system/marketing/times/card/remove',
    method: 'delete',
    data
  })
}

//会员次卡 详情次卡
export function timesCardDetailApi (timesCardId) {
  return request({
    url:
      '/api/system/marketing/times/card/getDetail?timesCardId=' + timesCardId,
    method: 'get'
  })
}

//会员次卡 修改次卡
export function timesCardUpdateApi (data) {
  return request({
    url: '/api/system/marketing/times/card/update',
    method: 'put',
    data
  })
}
