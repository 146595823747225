import request from '@/utils/request'

//短信模板   分页列表
export function templateListAPI (params) {
  return request({
    url: '/api/system/marketing/sms/template/list',
    method: 'get',
    params
  })
}
// 短信模板  新增
export function templateAddAPI (data) {
  return request({
    url: '/api/system/marketing/sms/template/save',
    method: 'post',
    data
  })
}
//短信模板  详情
export function templateDetailAPI (templateId) {
  return request({
    url:
      '/api/system/marketing/sms/template/getDetail?templateId=' + templateId,
    method: 'get'
  })
}
// 短信模板   修改
export function templateUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/sms/template/update',
    method: 'put',
    data
  })
}
// 短信模板  删除
export function templateDeleteAPI (templateIds) {
  return request({
    url: '/api/system/marketing/sms/template/delete',
    method: 'put',
    data: { templateIds }
  })
}
