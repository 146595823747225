import request from '@/utils/request'

// 商户列表
export function saasTenantList (params) {
  return request({
    url: '/api/system/saas/tenant/list',
    params
  })
}

// 商户详情
export function saasTenantDetail (tenantId) {
  return request({
    url: `/api/system/saas/tenant/${tenantId}`,
    method: 'get'
  })
}
// // 修改商户信息
// export function updateTenant(data) {
//     return request({
//         url: `/api/system/saas/tenant/updateTenant`,
//         method: 'put',
//         data: data
//     })
// }

// 修改商户状态
export function changeStatusAPI (data) {
  return request({
    url: '/api/system/saas/tenant/changeStatus',
    method: 'put',
    data
  })
}

// 获取产品菜单信息
export function getProductMenu (productId) {
  return request({
    url: `/api/system/product/menu/getProductMenu?productId=${productId}`,
    method: 'get'
  })
}

// 获取产品列表菜单信息
export function getProductMenuList (params) {
  return request({
    url: `/api/system/tenant/menu/getTenantProductMenu`,
    method: 'get',
    params
  })
}

// 修改产品菜单信息
export function updateProductMenu (data) {
  return request({
    url: '/api/system/tenant/menu/updateTenantMenu',
    method: 'post',
    data
  })
}
// 获取商户要续费的产品
export function getTenantProductToRenew (data) {
  return request({
    url: `/api/system/saas/tenant/getTenantProductToRenew`,
    method: 'post',
    data
  })
}
// 商户产品续费  管理员操作
export function saasTenantRenew (data) {
  return request({
    url: '/api/system/saas/tenant/renew',
    method: 'post',
    data
  })
}

// 商户产品续费  用户操作
export function renewProductByUser (data) {
  return request({
    url: '/api/system/saas/tenant/renewProductByUser',
    method: 'post',
    data
  })
}

// 获取版本升级补差金额
export function getMakeUpForDifferences (data) {
  return request({
    url: `/api/system/saas/tenant/getMakeUpForDifferences`,
    method: 'post',
    data
  })
}
// 产品升级
export function upgradeProduct (data) {
  return request({
    url: '/api/system/saas/tenant/upgradeProduct',
    method: 'post',
    data
  })
}

// 获取系统所有产品
export function getProductList (params) {
  return request({
    url: '/api/system/tenant/product/listSaasProductVersion',
    method: 'get',
    params
  })
}

// 获取商户购买过的产品对应的用户数、门店数
export function getProductUserShopQty (data) {
  return request({
    url: '/api/system/saas/tenant/getUpgradeProductVersion',
    method: 'get',
    params: data
  })
}

// 购买
export const buyOrderData = data => {
  return request({
    url: '/api/system/tenant/product/purchase/createBuyProductOrder',
    method: 'post',
    data
  })
}

// 获取产品升级的包年包月付费方式
export function getToUpgradeProductPayModel (params) {
  return request({
    url: '/api/system/saas/tenant/getToUpgradeProductPayModel',
    method: 'get',
    params
  })
}

// 停用产品
export function tenantProductStopUsing (data) {
  return request({
    url: '/api/system/tenant/product/stopUsing',
    method: 'post',
    data
  })
}
