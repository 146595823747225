import request from '@/utils/request'

// 生产配料分页列表
export function listBillInfo(data) {
  return request({
    url: '/api/system/produce/bill/match/material/listBillInfo',
    method: 'post',
    data
  })
}
// 新增生产配料
export function addMixMaterial(data) {
  return request({
    url: '/api/system/produce/bill/match/material/addMixMaterial',
    method: 'post',
    data
  })
}
//获取生产配料详情
export function getDetailInfo(billId) {
  return request({
    url: '/api/system/produce/bill/match/material/getDetailInfo',
    method: 'post',
    data: { billId }
  })
}
// 修改生产配料
export function modifyMixMaterial(data) {
  return request({
    url: '/api/system/produce/bill/match/material/modifyMixMaterial',
    method: 'post',
    data
  })
}
// 更改状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/produce/bill/match/material/modifyBillStatus',
    method: 'post',
    data
  })
}


// 原料订货
export function rawMaterialOrdering(data) {
  return request({
    url: '/api/system/produce/bill/match/material/rawMaterialOrdering',
    method: 'post',
    data
  })
}

// 半成品生产
export function halfGoodsProduce(data) {
  return request({
    url: '/api/system/produce/bill/match/material/halfGoodsProduce',
    method: 'post',
    data
  })
}

// 原料调入
export function rawMaterialMoveIn(data) {
  return request({
    url: '/api/system/produce/bill/match/material/rawMaterialMoveIn',
    method: 'post',
    data
  })
}

// 配料领用
export function materialpickMaterial(data) {
  return request({
    url: '/api/system/produce/bill/match/material/pickMaterial',
    method: 'post',
    data
  })
}

// 获取计划单明细列表
export function listPlanDetails(data) {
  return request({
    url: '/api/system/produce/plan/listPlanDetails',
    method: 'post',
    data
  })
}

// 获取计划单明细列表 确定
export function mixMaterial(data) {
  return request({
    url: '/api/system/produce/bill/match/material/mixMaterial',
    method: 'post',
    data
  })
}

// 刷新库存
export function refreshStock(data) {
  return request({
    url: '/api/system/produce/bill/match/material/refreshStock',
    method: 'post',
    data
  })
}

// 替换料
export function replaceMaterial(data) {
  return request({
    url: '/api/system/produce/bill/match/material/replaceMaterial',
    method: 'post',
    data
  })
}

//   配料单相关单据列表
export function listImportBillInfo(billId) {
  return request({
    url: '/api/system/produce/bill/match/material/listImportBillInfo',
    method: 'post',
    data: { billId }
  })
}