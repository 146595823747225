import request from '@/utils/request'

// 查询岗位列表
export function listPost(query) {
  return request({
    url: '/api/system/post/list',
    method: 'get',
    params: query
  })
}

// 查询岗位详细
export function getPost(postId) {
  return request({
    url: '/api/system/post/' + postId,
    method: 'get'
  })
}

// 新增岗位
export function addPost(data) {
  return request({
    url: '/api/system/post',
    method: 'post',
    data: data
  })
}

// 修改岗位
export function updatePost(data) {
  return request({
    url: '/api/system/post',
    method: 'put',
    data: data
  })
}

// 删除岗位
export function delPost(data) {
  return request({
    url: '/api/system/post/remove',
    method: 'delete',
    data
  })
}


//还原岗位
export function revertPost(data) {
  return request({
    url: '/api/system/post/revert',
    method: 'post',
    data
  })
}
