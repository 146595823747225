import request from '@/utils/request'
// 查询生产入库明细列表
/* export function storeDetailAPI (data) {
  return request({
    url: '/api/system/produce/storage/report/listStorageDetails',
    method: 'post',
    data
  })
}
 */

export function storeDetailAPI (params) {
  return request({
    url: '/api/system/produce/storage/report/listStorageDetails',
    method: 'get',
    params
  })
}