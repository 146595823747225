import request from '@/utils/request'

// 分店收银员分页列表
export function listShopCashier (query) {
  return request({
    url: '/api/system/shop/base/cashier/list',
    method: 'get',
    params: query
  })
}

// 分店收银机分页列表
export function listShopmachine (query) {
  return request({
    url: '/api/system/shop/base/machine/list',
    method: 'get',
    params: query
  })
}

// 分店付款方式分页列表
export function listPayClass (query) {
  return request({
    url: '/api/system/shop/base/pay/list',
    method: 'get',
    params: query
  })
}

// 查询分店收银员详细
export function getShopCashierDetail (cashierId) {
  return request({
    url: '/api/system/shop/base/cashier/getDetail?cashierId=' + cashierId,
    method: 'get'
  })
}

// 查询分店收银机详细
export function getShopMachineIdDetail (machineId) {
  return request({
    url: '/api/system/shop/base/machine/getDetail?machineId=' + machineId,
    method: 'get'
  })
}

// 新增分店收银员
export function addShopCashier (data) {
  return request({
    url: '/api/system/shop/base/cashier/save',
    method: 'post',
    data: data
  })
}

// 修改分店收银员
export function updateShopCashier (data) {
  return request({
    url: '/api/system/shop/base/cashier/update',
    method: 'put',
    data: data
  })
}

// 删除分店收银员
export function delShopCashier (cashierIds) {
  return request({
    url: '/api/system/shop/base/cashier/remove',
    method: 'delete',
    data: cashierIds
  })
}
// 新增门店收银员(组)权限
export function savePermissions (data) {
  return request({
    url: '/api/system/cashier/permissions/savePermissions',
    method: 'post',
    data: data
  })
}
// 获取门店收银员(组)权限详情
export function permissions (params) {
  return request({
    url: '/api/system/cashier/permissions/getPermissions',
    method: 'get',
    params: params
  })
}

// 还原收银员
export function restoreCashier (data) {
  return request({
    url: '/api/system/shop/base/cashier/restoreCashier',
    method: 'post',
    data
  })
}

// 复制收银员权限
export function copyPermissions (data) {
  return request({
    url: '/api/system/shop/base/cashier/copyPermissions',
    method: 'post',
    data
  })
}

// 付款方式商户分页分页列表
export function tenantListPage (query) {
  return request({
    url: '/api/system/shop/base/pay/tenantListPage',
    method: 'get',
    params: query
  })
}
