import request from '@/utils/request'

//获取业务数据参数   分页列表
export function dataListAPI (params) {
  return request({
    url: '/api/system/sysParam/getSysParamDataConfig',
    method: 'get',
    params
  })
}
// 添加/修改数据参数配置
export function dataAddUpdateAPI(data) {
  return request({
    url: '/api/system/sysParam/saveSysParamDataConfig',
    method: 'put',
    data: data
  })
}