import request from '@/utils/request'

//销售完成比查询(年度)   分页列表
export function yearRateAPI(params) {
  return request({
    url: '/api/system/shop/report/performance/statistics/listYear',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售完成比查询(月份)   分页列表
export function monthRateAPI(params) {
  return request({
    url: '/api/system/shop/report/performance/statistics/listMonth',
    method: 'get',
    timeout: 30000,
    params
  })
}

//充值完成比查询(年度)   分页列表
export function listRechargeYear(params) {
  return request({
    url: '/api/system/shop/report/performance/statistics/listRechargeYear',
    method: 'get',
    timeout: 30000,
    params
  })
}

//充值完成比查询(月份)   分页列表
export function listRechargeMonth(params) {
  return request({
    url: '/api/system/shop/report/performance/statistics/listRechargeMonth',
    method: 'get',
    timeout: 30000,
    params
  })
}
