import request from '@/utils/request'

// 生产领料差异分析
// export function differenceAnalysisAPI (data) {
//   return request({
//     url: '/api/system/produce/report/listPickingDifferenceAnalysis',
//     method: 'post',
//     data
//   })
// }

export function differenceAnalysisAPI (params) {
  return request({
    url: '/api/system/produce/report/listPickingDifferenceAnalysis',
    method: 'get',
    params
  })
}