import request from '@/utils/request'

//会员营销生日有礼   分页列表
export function joinGiftListAPI (params) {
  return request({
    url: '/api/system/marketing/vip/join/gift/list',
    method: 'get',
    params
  })
}
// 会员营销生日有礼  新增
export function joinGiftAddAPI (data) {
  return request({
    url: '/api/system/marketing/vip/join/gift/save',
    method: 'post',
    data
  })
}
//会员营销生日有礼  详情
export function joinGiftDetailAPI (billId) {
  return request({
    url: '/api/system/marketing/vip/join/gift/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 会员营销生日有礼   修改
export function joinGiftUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/vip/join/gift/update',
    method: 'put',
    data
  })
}

// 会员营销生日有礼  启用/停用
export function joinGiftIsStopAPI (data) {
  return request({
    url: '/api/system/marketing/vip/join/gift/isStop',
    method: 'put',
    data
  })
}

//会员营销生日有礼   更改状态
export function joinGiftUpdateStatusAPI (data) {
  return request({
    url: '/api/system/marketing/vip/join/gift/updateStatus',
    method: 'post',
    data
  })
}
