import request from '@/utils/request'

//门店分页列表
export function licenseList(params) {
  return request({
    url: '/api/system/shop/license/list',
    method: 'get',
    params
  })
}

//修改\新增门店
export function saveOrUpdate(data) {
  return request({
    url: '/api/system/shop/license/saveOrUpdate',
    method: 'post',
    data
  })
}

//获取抖音门店详情
export function getShopDetail(shopId) {
  return request({
    url: '/api/system/shop/license/getDetail?shopId=' + shopId,
    method: 'get'
  })
}

//删除抖音门店
export function licenseDelete(data) {
  return request({
    url: '/api/system/shop/license/remove',
    method: 'delete',
    data
  })
}

//还原
export function licenseRestore(data) {
  return request({
    url: '/api/system/shop/license/restore',
    method: 'post',
    data
  })
}