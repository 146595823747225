import request from '@/utils/request'

//会员次卡持有明细查询 分页列表
export function vipMarketingTimesDetail(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesDetail',
    method: 'get',
    params
  })
}

//会员次卡持有明细查询 分页汇总
export function vipMarketingTimesDetailSummary(params) {
  return request({
    url: '/api/system/marketing/times/card/reportForms/vipMarketingTimesDetailSummary',
    method: 'get',
    params
  })
}