import request from '@/utils/request'
// 生产入库按仓库汇总
// export function storeAPI (data) {
//   return request({
//     url: '/api/system/produce/storage/report/listStorageByStore',
//     method: 'post',
//     data
//   })
// }

// 生产入库按商品汇总
// export function goodsAPI (data) {
//   return request({
//     url: '/api/system/produce/storage/report/listStorageByGoods',
//     method: 'post',
//     data
//   })
// }
// 生产入库每日按商品汇总
// export function dayGoodsAPI (data) {
//   return request({
//     url: '/api/system/produce/storage/report/listStorageDateByGoods',
//     method: 'post',
//     data
//   })
// }

//生产入库按部门仓库汇总
// export function deptStoreAPI (data) {
//   return request({
//     url: '/api/system/produce/storage/report/listStorageByDeptStore',
//     method: 'post',
//     data
//   })
// }




export function storeAPI (params) {
  return request({
    url: '/api/system/produce/storage/report/listStorageByStore',
    method: 'get',
    params
  })
}
export function goodsAPI (params) {
  return request({
    url: '/api/system/produce/storage/report/listStorageByGoods',
    method: 'get', 
    params
  })
}
export function dayGoodsAPI (params) {
  return request({
    url: '/api/system/produce/storage/report/listStorageDateByGoods',
    method: 'get',
    params
  })
}
export function deptStoreAPI (params) {
  return request({
    url: '/api/system/produce/storage/report/listStorageByDeptStore',
    method: 'get',
    params
  })
}