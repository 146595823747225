import request from '@/utils/request'

// 查询用户登录日志列表
export function list(query) {
  return request({
    url: '/api/system/monitor/user/log/list',
    method: 'get',
    params: query
  })
}

// 删除用户登录日志
export function delUserLog(data) {
  return request({
    url: '/api/system/monitor/user/log/delete',
    method: 'delete',
    data
  })
}

// 清空用户登录日志
export function cleanUserLog() {
  return request({
    url: '/api/system/monitor/user/log/clean',
    method: 'delete'
  })
}

// 查询用户登录日志列表  (商户)
export function listTenantPage(query) {
  return request({
    url: '/api/system/monitor/user/log/listTenantPage',
    method: 'get',
    params: query
  })
}

// 清空用户登录日志  (商户)
export function deleteByTenantId() {
  return request({
    url: '/api/system/monitor/user/log/deleteByTenantId',
    method: 'delete'
  })
}
