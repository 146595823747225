import request from '@/utils/request'

// 门店预订单分页列表
export function listOrder(query) {
  return request({
    url: '/api/system/shop/bill/preOrder/billOrderListCommonList',
    method: 'get',
    params: query
  })
}

//获取门店预订单详情
export function getOrderDetail(billId) {
  return request({
    url: '/api/system/shop/bill/preOrder/getShopBillOrderInfo',
    method: 'get',
    params: { billId }
  })
}

// 添加门店预订单
export function addOrder(data) {
  return request({
    url: '/api/system/shop/bill/preOrder/saveShopBillOrder',
    method: 'post',
    data: data
  })
}

// 修改门店预订单
export function updateOrder(data) {
  return request({
    url: '/api/system/shop/bill/preOrder/updateShopBillOrder',
    method: 'post',
    data: data
  })
}

// 修改审核状态、关闭、删除
export function updateOrderStatus(data) {
  return request({
    url: '/api/system/shop/bill/preOrder/updateBillStatus',
    method: 'POST',
    data
  })
}

// 修改获取生产部门信息
export function getOrderList(data) {
  return request({
    url: '/api/system/shop/bill/preOrder/productionDepartmentList',
    method: 'get',
    data
  })
}

// 导出
export function exportOrderList(data) {
  return request({
    url: '/api/system/shop/bill/preOrder/billOrderListCommonListExport',
    method: 'post',
    data
  })
}

// 复制门店预订单
export function copyShopPreOrder(data) {
  return request({
    url: '/api/system/shop/bill/preOrder/copyShopPreOrder',
    method: 'post',
    data
  })
}