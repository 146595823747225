import request from '@/utils/request'

//优惠券发放   分页列表
export function takeOutListAPI (params) {
  return request({
    url: '/api/system/marketing/base/coupon/send/out/list',
    method: 'get',
    params
  })
}
// 优惠券发放  新增
export function takeOutAddAPI (data) {
  return request({
    url: '/api/system/marketing/base/coupon/send/out/save',
    method: 'post',
    data
  })
}
//优惠券发放  详情
export function takeOutDetailAPI (billId) {
  return request({
    url:
      '/api/system/marketing/base/coupon/send/out/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 优惠券发放   修改
export function takeOutUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/base/coupon/send/out/update',
    method: 'put',
    data
  })
}
// 优惠券发放 更改状态
export function takeOutUpdateStatusAPI (data) {
  return request({
    url: '/api/system/marketing/base/coupon/send/out/updateStatus',
    method: 'post',
    data
  })
}

//优惠券发放  详情
export function getUpdateStatusState (billId) {
  return request({
    url:'/api/system/marketing/base/coupon/send/out/getUpdateStatusState?stateKey=' + billId,
    method: 'get'
  })
}