import request from '@/utils/request'


// 根据字典类型查询业务字典数据信息
export function getBusiness(dictType) {
  return request({
    url: '/api/system/dict/data/business/' + dictType,
    method: 'get'
  })
}



