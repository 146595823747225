import request from '@/utils/request'

//采购明细报表   分页列表
export function reportDetailListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/detailReportForms',
    method: 'get',
    params
  })
}
//采购明细报表   分页列表合计
export function sumDetailReportForms (params) {
  return request({
    url: '/api/system/purchase/reportForms/sumDetailReportForms',
    method: 'get',
    params
  })
}
//采购汇总报表   分页列表
export function summaryListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/detailGoodsSummaryForms',
    method: 'get',
    params
  })
}
//采购汇总报表   分页列表
export function sumDetailGoodsSummaryForms (params) {
  return request({
    url: '/api/system/purchase/reportForms/sumDetailGoodsSummaryForms',
    method: 'get',
    params
  })
}
//采购订单汇总报表   分页列表
export function orderSummaryListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/purchaseOrderForms',
    method: 'get',
    params
  })
}
//采购订单汇总报表   分页列表
export function sumOrderSummaryListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/sumPurchaseOrderForms',
    method: 'get',
    params
  })
}
//采购趋势   分页列表
export function dataAnalyseListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/purchaseTrend',
    method: 'get',
    params
  })
}

//采购分布   分页列表
export function distributionListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/purchaseDistribution',
    method: 'get',
    params
  })
}

//采购排行   分页列表
export function rankeNameListAPI (params) {
  return request({
    url: '/api/system/purchase/reportForms/purchaseRanking',
    method: 'get',
    params
  })
}

//采购价格分析  分页列表
export function purchasePriceAnalysis (params) {
  return request({
    url: '/api/system/purchase/reportForms/purchasePriceAnalysis',
    method: 'get',
    params
  })
}

//采购价格分析   汇总列表
export function purchasePriceAnalysisSummary (params) {
  return request({
    url: '/api/system/purchase/reportForms/sumPurchasePriceAnalysis',
    method: 'get',
    params
  })
}
