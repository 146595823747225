import request from '@/utils/request'

//导购员提成查询
export function commissionListAPI(params) {
  return request({
    url: '/api/system/shop/guideMan/query/commissionQuery',
    method: 'get',
    params
  })
}
//导购员充值查询
export function rechargeListAPI(params) {
  return request({
    url: '/api/system/shop/guideMan/query/fillQuery',
    method: 'get',
    params
  })
}
//导购员销售查询
export function saleListAPI(params) {
  return request({
    url: '/api/system/shop/guideMan/query/saleQuery',
    method: 'get',
    params
  })
}

//导购员提成查询  汇总
export function commissionListAPISummary(params) {
  return request({
    url: '/api/system/shop/guideMan/query/commissionQuerySummary',
    method: 'get',
    params
  })
}

//导购员销售查询  汇总
export function saleListAPISummary(params) {
  return request({
    url: '/api/system/shop/guideMan/query/saleQuerySummary',
    method: 'get',
    params
  })
}

//导购员充值查询
export function rechargeListAPISummary(params) {
  return request({
    url: '/api/system/shop/guideMan/query/fillQuerySummary',
    method: 'get',
    params
  })
}