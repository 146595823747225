import request from '@/utils/request'

//系统产品对比设置

// 系统产品对比设置分页列表
export function compareList(params) {
    return request({
      url: '/api/system/tenant/compare/list',
      params
    })
  }

// 系统产品对比设置tree列表
export function compareTreeList() {
  return request({
    url: '/api/system/tenant/compare/listTree',
  })
}

// 系统产品对比设置新增
export function saveCompare(data) {
    return request({
      url: '/api/system/tenant/compare/save',
      method: 'POST',
      data
    })
  }

// 系统产品对比设置修改
export function updateCompare(data) {
    return request({
      url: '/api/system/tenant/compare/update',
      method: 'PUT',
      data
    })
  }

// 系统产品对比设置详情
export function compareDetail(compareFuncId) {
    return request({
      url: `/api/system/tenant/compare/getDetail?compareFuncId=${compareFuncId}`,
    })
  }

// 系统产品对比设置删除
export function delCompare(compareFuncIds) {
    return request({
      url: '/api/system/tenant/compare/remove',
      method: 'DELETE',
      data:compareFuncIds
    })
  }