import request from '@/utils/request'

//期初建账   分页列表
export function goodsStockListAPI (params) {
  return request({
    url: '/api/system/goods/first/book/list',
    method: 'get',
    params
  })
}
// 期初建账    批量保存
export function goodsStockBatchSaveAPI (data) {
  return request({
    url: '/api/system/goods/first/book/batchSave',
    method: 'post',
    data
  })
}
//期初建账  详情
export function goodsStockDetailAPI (firstBookId) {
  return request({
    url: '/api/system/goods/first/book/getDetail?firstBookId=' + firstBookId,
    method: 'get'
  })
}
//期初建账   批量获取商品估计成本价格
export function goodsStockCostPriceAPI (params) {
  return request({
    url: '/api/system/goods/first/book/getCostPrice',
    method: 'get',
    params
  })
}
//期初建账 删除
export function goodsStockDeleteAPI (firstBookIds) {
  return request({
    url: '/api/system/goods/first/book/delete',
    method: 'put',
    data: { firstBookIds }
  })
}
// 期初建账    期初建账
export function initialDataGoodsStoreBook () {
  return request({
    url: '/api/system/goods/first/book/initialDataGoodsStoreBook',
    method: 'post'
  })
}
//期初建账   是否已经执行过期初建账
export function isAlreadyInitialDataBookDetail () {
  return request({
    url: '/api/system/goods/first/book/isAlreadyInitialDataBookDetail',
    method: 'get'
  })
}
