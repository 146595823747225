import request from '@/utils/request'

// 生产领料分页列表
export function leadMaterialListAPI(params) {
  return request({
    url: '/api/system/produce/bill/picking/list',
    method: 'get',
    params
  })
}
// 新增生产领料
export function leadMaterialAddAPI(data) {
  return request({
    url: '/api/system/produce/bill/picking/save',
    method: 'post',
    data
  })
}
//获取生产领料详情
export function leadMaterialDetailAPI(billId) {
  return request({
    url: '/api/system/produce/bill/picking/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 修改生产领料
export function leadMaterialUpdateAPI(data) {
  return request({
    url: '/api/system/produce/bill/picking/update',
    method: 'put',
    data
  })
}
// 更改状态
export function leadMaterialUpdateStatusAPI(data) {
  return request({
    url: '/api/system/produce/bill/picking/updateBillStatus',
    method: 'post',
    data
  })
}
// 查询生产领料的数据  （通用）
export function findDetailGoodsInfoAPI(data) {
  return request({
    url: '/api/system/produce/bill/base/findDetailGoodsInfo',
    method: 'post',
    data
  })
}

//   领料单相关单据列表
export function listImportBillInfo(billId) {
  return request({
    url: '/api/system/produce/bill/picking/listImportBillInfo',
    method: 'post',
    data: { billId }
  })
}
