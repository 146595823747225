import request from "@/utils/request";

// 分店收银员分组分页列表
export function listShopCashierGroup(query) {
  return request({
    url: "/api/system/shop/base/cashier/group/list",
    method: "get",
    params: query,
  });
}

// 查询分店收银员分组详细
export function getShopCashierGroupDetail(cashierGroupId) {
  return request({
    url:
      "/api/system/shop/base/cashier/group/getDetail?cashierGroupId=" +
      cashierGroupId,
    method: "get",
  });
}

// 新增分店收银员分组
export function addShopCashierGroup(data) {
  return request({
    url: "/api/system/shop/base/cashier/group/save",
    method: "post",
    data: data,
  });
}

// 修改分店收银员分组
export function updateShopCashierGroup(data) {
  return request({
    url: "/api/system/shop/base/cashier/group/update",
    method: "put",
    data: data,
  });
}

// 删除分店收银员分组
export function delShopCashierGroup(cashierGroupIds) {
  return request({
    url: "/api/system/shop/base/cashier/group/remove",
    method: "delete",
    data: cashierGroupIds,
  });
}

// 查询分店收银员分组树
export function getCashierTree(query) {
  return request({
    url: "/api/system/shop/base/cashier/group/listTree",
    method: "get",
    params: query,
  });
}
