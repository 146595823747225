import request from '@/utils/request'

//门店原料订货报表   分页列表
export function materialOrderListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopBillMaterialOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店原料订货报表   合计行
export function materialOrderListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopBillMaterialOrderSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店订货报表      分页列表
export function shopGoodsListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopBillOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店订货报表      合计行
export function shopGoodsListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopBillOrderSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店调入报表      分页列表
export function ShopInListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopIn',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店调入报表      合计行
export function ShopInListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopInSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店调出报表      分页列表
export function ShopOutListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopOut',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店调出报表      合计行
export function ShopOutListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopOutSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店生产报表      分页列表
export function shopProduceListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopProduce',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店生产报表      合计行
export function shopProduceListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopProduceSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店采购报表      分页列表
export function shopPurchaseListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopPurchase',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店采购报表      合计行
export function shopPurchaseListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopPurchaseSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店报废报表      分页列表
export function shopScrapListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopScrap',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店报废报表      合计行
export function shopScrapListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopScrapSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店领料报表      分页列表
export function shopPickingListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopPicking',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店领料报表      合计行
export function shopPickingListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopPickingSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 门店盘点单查询      分页列表
export function shopCheckListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopCheck',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 门店盘点单查询      合计行
export function shopCheckListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopCheckSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 获取仓库调出分页列表
export function outStoreAPI(params) {
  return request({
    url: '/api/system/store/bill/out/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 门店销售单、门店销售订单      分页列表
export function listShopSaleOrderAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopSaleOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 门店销售单、门店销售订单      合计行
export function listShopSaleOrderSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopSaleOrderSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
