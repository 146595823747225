import request from '@/utils/request'

// 平台短信模板分页列表
export function msgTemplateList(params) {
    return request({
      url: `/api/system/tenant/msg/template/list`,
      params
    })
  }

// 新增平台短信模板
export function saveMsgTemplate(data) {
    return request({
      url: `/api/system/tenant/msg/template/save`,
      method:"POST",
      data
    })
  }

// 修改平台短信模板
export function updateMsgTemplate(data) {
    return request({
      url: `/api/system/tenant/msg/template/update`,
      method:"PUT",
      data
    })
  }

// 平台短信模板详情
export function msgTemplateDetail(templateId) {
    return request({
      url: `/api/system/tenant/msg/template/getDetail?templateId=${templateId}`,
    })
  }

// 删除平台短信模板
export function delMsgTemplate(templateIds) {
    return request({
      url: `/api/system/tenant/msg/template/remove`,
      method:"DELETE",
      data:templateIds
    })
  }