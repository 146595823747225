import request from '@/utils/request'

//门店订单中台   分页列表
export function orderMiddleListAPI(params) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getOrderMiddleFroundList',
    method: 'get',
    params
  })
}

// 门店订单中台详情
export function orderMiddleInfoAPI(billId) {
  return request({
    url:
      '/api/system/shop/base/orderMiddleGround/getOrderMiddleFroundInfo?billId=' +
      billId,
    method: 'get'
  })
}

// 门店订单中台状态
export function orderStateAPI(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/orderState',
    method: 'post',
    data
  })
}

// 获取门店订单提示开关参数设置
export function shopOrderTipConfig(data) {
  return request({
    url: '/api/system/shop/config/getShopOrderTipConfig',
    method: 'get',
    data
  })
}

// 新增/更新门店订单提示开关参数设置
export function saveShopOrderTipConfig(data) {
  return request({
    url: '/api/system/shop/config/saveShopOrderTipConfig',
    method: 'post',
    data
  })
}

//查看订单详情门店订单接口跟（门店预售订单）
export function getShopBillOrderInfo(billId) {
  return request({
    url:
      'api/system/shop/base/orderMiddleGround/getShopBillOrderInfo?billId=' +
      billId,
    method: 'get'
  })
}

// 新增门店零售单
export function saveShopBillPosRetail(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/saveShopBillPosRetail',
    method: 'post',
    data
  })
}

//零售单后台取货订单编号
export function getShopBillPosNo() {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getShopBillPosNo',
    method: 'get'
  })
}

// 生成调出单
export function shopBillPosOut(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/shopBillPosOut',
    method: 'post',
    data
  })
}

// 生成调出单调入单
export function shopBillPosOutAndIn(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/shopBillPosOutAndIn',
    method: 'post',
    data
  })
}

//获取该商户对应的订单中台调出仓库
export function getTenantOutStore() {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getTenantOutStore',
    method: 'get'
  })
}

//存储该商户对应的订单中台调出仓库
export function setTenantOutStore(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/setTenantOutStore',
    method: 'post',
    data
  })
}

//取消预定
export function cancelShopBillOrder(billId) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/cancellation',
    method: 'post',
    data: { billId }
  })
}

//通知生产
export function notifyProduction(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/noticeProduce',
    method: 'post',
    data
  })
}

//同步裱花间
export function departmentList() {
  return request({
    url: '/api/system/decoration/room/departmentList',
    method: 'get'
  })
}

//门店订单中台   合计行
export function orderMiddleListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getOrderMiddleFroundListSummary',
    method: 'get',
    params
  })
}

//修改预订
export function updateShopBillOrder(data) {
  return request({
    url: 'api/system/shop/base/orderMiddleGround/updateShopBillOrder',
    method: 'post',
    data
  })
}

//裱花间、部门
export function productionDepartmentList(params) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/productionDepartmentList',
    method: 'get',
    params
  })
}

//   厨打单据
export function getNoticeProduce(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getNoticeProduce',
    method: 'post',
    data
  })
}

//  获取总部门店
export function getAssembleShopInfo(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getAssembleShopInfo',
    method: 'post',
    data
  })
}

//呼叫配送
export function getCallDelivery(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/callDelivery',
    method: 'post',
    data
  })
}

//获取所有绑定的物流账号
export function getAllAccount() {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getAllAccount',
    method: 'get',
  })
}

//取消配送
export function getCancelDelivery(data) {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/cancelDelivery',
    method: 'post',
    data
  })
}

//查找已开通的
export function getOpenAppTypes() {
  return request({
    url: '/api/system/shop/base/orderMiddleGround/getOpenAppTypes',
    method: 'get',
  })
}