import request from '@/utils/request'

//
export function getOrderDetail (params) {
  return request({
    url: '/api/system/shop/base/man/getOrderDetail',
    method: 'get',
    params
  })
}

// 生成订单(需要先生成订单后再去调用支付二维码)
export function generateOrder (data) {
  return request({
    url: '/api/system/shop/base/man/generateOrder',
    method: 'post',
    data
  })
}
export function orderPay (data) {
  return request({
    url: '/api/system/shop/base/man/orderPay',
    method: 'post',
    data
  })
}

export function getManList (params) {
  return request({
    url: '/api/system/shop/base/man/list',
    method: 'GET',
    params
  })
}

export function getOrderState (params) {
  return request({
    url: '/api/system/shop/base/man/getOrderState',
    method: 'GET',
    params
  })
}

export function getOrderList (params) {
  return request({
    url: '/api/system/tenant/account/order/list',
    method: 'GET',
    params
  })
}

export function getMyOrderList (params) {
  return request({
    url: '/api/system/tenant/account/order/listUserOrder',
    method: 'GET',
    params
  })
}

export function save (data) {
  return request({
    url: '/api/system/shop/base/man/save',
    method: 'POST',
    data
  })
}

export function remove (data) {
  return request({
    url: '/api/system/shop/base/man/remove',
    method: 'DELETE',
    data
  })
}

export function update (data) {
  return request({
    url: '/api/system/shop/base/man/update',
    method: 'PUT',
    data
  })
}

// 申请退款
export function orderRefundOrder (data) {
  return request({
    url: '/api/system/tenant/account/order/refundOrder',
    method: 'post',
    data
  })
}

// 详情
export const orderDetails = params => {
  return request({
    url: '/api/system/tenant/account/order/refundOrder',
    method: 'get',
    params
  })
}

//获取要续费的营业通产品
export function getTradeProductToRenew (data) {
  return request({
    url: '/api/system/saas/trade/product/getTradeProductToRenew',
    method: 'post',
    data
  })
}

//营业通产品订单分页列表
export function getTradeProductOrder (params) {
  return request({
    url: '/api/system/saas/trade/product/getTradeProductOrder',
    method: 'GET',
    params
  })
}

// 续费订单(需要先生成订单后再去调用支付二维码)
export function tradeProductRenew (data) {
  return request({
    url: '/api/system/saas/trade/product/tradeProductRenew',
    method: 'post',
    data
  })
}
