import request from '@/utils/request'

//短信提醒   分页列表
export function remindListAPI (params) {
  return request({
    url: '/api/system/marketing/sms/remind/config/list',
    method: 'get',
    params
  })
}
//短信提醒  详情
export function remindDetailAPI (remindId) {
  return request({
    url:
      '/api/system/marketing/sms/remind/config/getDetail?remindId=' + remindId,
    method: 'get'
  })
}
// 短信提醒   修改
export function remindUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/sms/remind/config/update',
    method: 'put',
    data
  })
}

// 短信提醒   新增
export function remindSaveAPI (data) {
  return request({
    url: '/api/system/marketing/sms/remind/config/save',
    method: 'put',
    data
  })
}

// 短信提醒   删除
export function remindRemoveAPI (data) {
  return request({
    url: '/api/system/marketing/sms/remind/config/remove',
    method: 'delete',
    data
  })
}

// 短信提醒   发送测试
export function celTelAPI (data) {
  return request({
    url: '/api/system/marketing/sms/remind/config/send',
    method: 'post',
    data
  })
}

//获取短信参数
export function getMarketingSmsRemindConfigParameter (remindTypeId) {
  return request({
    url:
      '/api/system/marketing/sms/remind/config/getMarketingSmsRemindConfigParameter?remindTypeId=' +
      remindTypeId,
    method: 'get'
  })
}
