import request from '@/utils/request'

//会员营销生日有礼   分页列表
export function precisionListAPI(params) {
  return request({
    url: '/api/system/marketing/vip/precision/case/list',
    method: 'get',
    params
  })
}
// 会员营销生日有礼  新增
export function precisionAddAPI(data) {
  return request({
    url: '/api/system/marketing/vip/precision/case/save',
    method: 'post',
    data
  })
}
//会员营销生日有礼  详情
export function precisionDetailAPI(billId) {
  return request({
    url: '/api/system/marketing/vip/precision/case/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 会员营销生日有礼   修改
export function precisionUpdateAPI(data) {
  return request({
    url: '/api/system/marketing/vip/precision/case/update',
    method: 'put',
    data
  })
}

// 会员营销生日有礼  启用/停用
export function precisionIsStopAPI(data) {
  return request({
    url: '/api/system/marketing/vip/precision/case/isStop',
    method: 'put',
    data
  })
}

//会员营销生日有礼   更改状态
export function precisionUpdateStatusAPI(data) {
  return request({
    url: '/api/system/marketing/vip/precision/case/updateStatus',
    method: 'post',
    data
  })
}

//会员营销生日有礼   获取会员列表
export function precisionGetVipsAPI(data) {
  return request({
    url: '/api/system/marketing/vip/precision/case/getVip',
    method: 'post',
    data,
    timeout: 30000,
  })
}

// 复制
export function serviceCopyAPI (data) {
  return request({
    url: '/api/system/marketing/vip/precision/case/copy',
    method: 'post',
    data
  })
}