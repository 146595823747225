import request from '@/utils/request'

//导购员   分页列表
export function guideManListAPI (params) {
  return request({
    url: '/api/system/shop/guideMan/shopGuideMan/list',
    method: 'get',
    params
  })
}
// 导购员  新增
export function guideManAddAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/shopGuideMan/save',
    method: 'post',
    data
  })
}
//导购员  详情
export function guideManDetailAPI (guideManId) {
  return request({
    url:
      '/api/system/shop/guideMan/shopGuideMan/getDetail?guideManId=' +
      guideManId,
    method: 'get'
  })
}
// 导购员   修改
export function guideManUpdateAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/shopGuideMan/update',
    method: 'put',
    data
  })
}

// 导购员  删除
export function guideManDeleteAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/shopGuideMan/remove',
    method: 'DELETE',
    data
  })
}

// 导购员  结算
export function guideManSettlementAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/shopGuideMan/settlement',
    method: 'post',
    data
  })
}

// 还原导购员
export function restoreShopGuideMan (data) {
  return request({
    url: '/api/system/shop/guideMan/shopGuideMan/restoreShopGuideMan',
    method: 'post',
    data
  })
}
