import request from '@/utils/request'

// 调出订单列表
export function listOrder (data) {
  return request({
    url: '/api/system/shop/bill/base/findReferenceData',
    method: 'POST',
    data
  })
}
// 裱花调出订单列表
export function decorationFindReferenceData (data) {
  return request({
    url: '/api/system/decoration/bill/base/findReferenceData',
    method: 'POST',
    data
  })
}
// 裱花调出订单列表
export function freshlyBakedFindReferenceData (data) {
  return request({
    url: '/api/system/freshly/baked/bill/base/findReferenceData',
    method: 'POST',
    data
  })
}

//获取商品详情
export function getOrderDetail (billIds) {
  return request({
    url: '/api/system/shop/bill/base/findDetailGoodsInfo',
    method: 'POST',
    data: { billIds }
  })
}
//获取商品详情
export function getFindDetailGoodsInfo (billIds) {
  return request({
    url: '/api/system/decoration/bill/base/findDetailGoodsInfo',
    method: 'POST',
    data: billIds
  })
}
//获取商品详情
export function getfreshlyBakedDetailGoodsInfo (billIds) {
  return request({
    url: '/api/system/freshly/baked/bill/base/findDetailGoodsInfo',
    method: 'POST',
    data: billIds
  })
}

//获取商品详情
export function getOrderDetailData (data) {
  return request({
    url: '/api/system/shop/bill/base/findDetailGoodsInfo',
    method: 'POST',
    data: data
  })
}

// 门店订单门店原料订单  列表
export function shopListOrder (data) {
  return request({
    url: '/api/system/shop/bill/base/findShopBillOrderReferenceData',
    method: 'POST',
    data
  })
}

// 门店订单门店原料订单  订单包含商品
export function shopGoodsOrder (billIds) {
  return request({
    url: '/api/system/shop/bill/base/findShopOrderDetailGoodsReferenceData',
    method: 'POST',
    data: { billIds }
  })
}

// 取消引入
export function cancelImport (billId) {
  return request({
    url: '/api/system/shop/bill/base/cancelImport?billId=' + billId,
    method: 'put'
  })
}
