import request from '@/utils/request'

// 批量添加更新客户、供应商价格方案  新增
export function keGongPriceAddAPI (data) {
  return request({
    url: '/api/system/partner/supplier/saveOrUpdatePriceCase',
    method: 'post',
    data
  })
}
//客户、供应商价格方案  详情
export function keGongPriceDetailAPI (params) {
  return request({
    url: '/api/system/partner/supplier/getPriceCaseDetail',
    method: 'get',
    params
  })
}
