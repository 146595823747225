import request from '@/utils/request'

//门店库存查询   分页列表
export function storeQueryAPI(params) {
  return request({
    url: '/api/system/shop/report/inventory/query/listShopInventoryQuery',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店库存明细查询
export function getStoreBookDetail(params) {
  return request({
    url: '/api/system/shop/report/inventory/query/getStoreBookDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店库存  合计行
export function listShopInventoryQuerySummary(params) {
  return request({
    url: '/api/system/shop/report/inventory/query/listShopInventoryQuerySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
