import request from '@/utils/request'

//按单品查询报表   分页列表
export function singleListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listSingleItem',
    method: 'get',
    timeout: 30000,
    params
  })
}
//按单品查询报表   合计行
export function singleListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listSingleItemSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//按小票查询   分页列表
export function billListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listBillNo',
    method: 'get',
    timeout: 30000,
    params
  })
}
//按小票查询   合计行
export function billListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listBillNoSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//按小票查询明细   分页列表
export function billDetailListAPI(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderInfo',
    method: 'get',
    timeout: 30000,
    params
  })
}

//小票查询支付明细   分页列表
export function billPayDetailListAPI(billNo) {
  return request({
    url:
      '/api/system/shop/report/sale/summary/listBillNoPayDetail?billNo=' +
      billNo,
    timeout: 30000,
    method: 'get'
  })
}

//按收银员查询   分页列表
export function cashierListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listCashierSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//按收银员查询   合计行
export function cashierListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listCashierSummarySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//按分类汇总   分页列表
export function categoryListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listCategorySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//按分类汇总   合计行
export function categoryListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listCategorySummarySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//按时间段查询   分页列表
export function timeListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listTimeSlotSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//按时间段查询   合计行
export function timeListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listTimeSlotSummarySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//按小票查询明细   销售汇总小票的详情
export function listBillNoDetail(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listBillNoDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店汇总查询   分页列表
export function listShopSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listShopSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店汇总查询   合计行
export function listShopSummarySummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listShopSummarySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//收银机汇总查询   分页列表
export function listMachineSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listMachineSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//收银机汇总查询   合计行
export function listMachineSummarySummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listMachineSummarySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//日期汇总查询   分页列表
export function listDateSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listDateSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//日期汇总查询   合计行
export function listDateSummarySummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/summary/listDateSummarySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
