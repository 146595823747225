import request from '@/utils/request'

// 获取配送对象列表
export function listDeliverObject(data) {
  return request({
    url: '/api/system/line/listDeliverObject',
    method: 'POST',
    data
  })
}

// 更新配送对象路线
export function updateDeliverLineDistributionInfo(data) {
  return request({
    url: '/api/system/line/updateDeliverLineDistributionInfo',
    method: 'POST',
    data
  })
}