import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/baling'

// 查询用户列表
export function listUser (params) {
  return request({
    url: '/api/system/user/listUser',
    method: 'get',
    params
  })
}

// 查询用户详细
export function getUser (userId) {
  return request({
    url: '/api/system/user/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser (data) {
  return request({
    url: '/api/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser (data) {
  return request({
    url: '/api/system/user',
    method: 'put',
    data
  })
}

// 删除用户
export function delUser (userId) {
  return request({
    url: '/api/system/user/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd (data) {
  return request({
    url: '/api/system/user/resetPwd',
    method: 'put',
    data
  })
}

// 用户状态修改
export function changeUserStatus (userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/api/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile () {
  return request({
    url: '/api/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile (data) {
  return request({
    url: '/api/system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd (oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/api/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar (data) {
  return request({
    url: '/api/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 查询授权角色
export function getAuthRole (userId) {
  return request({
    url: '/api/system/user/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole (data) {
  return request({
    url: '/api/system/user/authRole',
    method: 'put',
    params: data
  })
}
// 发送短信验证码
export function sendCode (telephone) {
  return request({
    url: '/api/open/system/sms/sendCode?telephone=' + telephone,
    method: 'get'
  })
}
// 验证手机验证是否正确
export function smsVerifyCode (params) {
  return request({
    url: '/api/open/system/sms/verifyCode',
    method: 'get',
    params: params
  })
}

// 用户申请加入商户审核
export function auditUserTenantAPI (data) {
  return request({
    url: '/api/system/user/auditUserTenant',
    method: 'post',
    data: data
  })
}
// 通过UserId查询用户
export function findByUserId () {
  return request({
    url: '/api/system/user/findByUserId',
    method: 'get'
  })
}
