import request from '@/utils/request'

// 查询部门列表
export function listDept(query) {
  return request({
    url: '/api/system/dept/list',
    method: 'get',
    params: query
  })
}
// 查询部门编号|部门名称模糊搜索列表
export function listSimpleQuery(query) {
  return request({
    url: '/api/system/dept/simpleQuery',
    method: 'get',
    params: query
  })
}
// 查询部门列表（排除节点）
export function listDeptExcludeChild(deptId) {
  return request({
    url: '/api/system/dept/list/exclude/' + deptId,
    method: 'get'
  })
}

// 查询部门详细
export function getDept(deptId) {
  return request({
    url: '/api/system/dept/' + deptId,
    method: 'get'
  })
}

// 查询部门下拉树结构
export function treeselect() {
  return request({
    url: '/api/system/dept/treeSelect',
    method: 'get'
  })
}

// 根据角色ID查询部门树结构
export function roleDeptTreeselect(roleId) {
  return request({
    url: '/api/system/dept/roleDeptTreeSelect/' + roleId,
    method: 'get'
  })
}

// 新增部门
export function addDept(data) {
  return request({
    url: '/api/system/dept',
    method: 'post',
    data: data
  })
}

// 修改部门
export function updateDept(data) {
  return request({
    url: '/api/system/dept',
    method: 'put',
    data: data
  })
}

// 删除部门
// export function delDept (deptId) {
//   return request({
//     url: '/api/system/dept/' + deptId,
//     method: 'delete'
//   })
// }


export function delDept(deptId) {
  return request({
    url: '/api/system/dept/remove',
    method: 'delete',
    data: deptId
  })
}




// (通过账套id)获取部门下拉树列表
export function sysDeptList({ accountId, tenantId }) {
  return request({
    url: `/api/system/dept/sysTreeSelect?accountId=${accountId}&tenantId=${tenantId}`,
  })
}

// 还原被删除部门
export function revertDeptAPI(data) {
  return request({
    url: '/api/system/dept/revert',
    method: 'post',
    data: data
  })
}

// 记账类型
export function billPostTypeList() {
  return request({
    url: '/api/system/dict/data/system/bill_post_type',
    method: 'get'
  })
}
// 单据类型
export function billTypeList() {
  return request({
    url: '/api/system/dict/data/system/bill_type',
    method: 'get'
  })
}
// 出入库方式
export function inOutStoreList() {
  return request({
    url: '/api/system/dict/data/system/in_out_store',
    method: 'get'
  })
}

// 单据状态
export function dataBillStatus() {
  return request({
    url: '/api/system/dict/data/system/bill_status',
    method: 'get'
  })
}
// 调整方式
export function dataInOutStore() {
  return request({
    url: '/api/system/dict/data/system/in_out_store',
    method: 'get'
  })
}
