import request from '@/utils/request'

// 商品汇总查询生产领料
// export function summaryByGoodsAPI (data) {
//   return request({
//     url: '/api/system/produce/report/listPickingSummaryByGoods',
//     method:'post',
//     data
//   })
// }

export function summaryByGoodsAPI (params) {
  return request({
    url: '/api/system/produce/report/listPickingSummaryByGoods',
    method: 'get',
    params
  })
}
// 仓库汇总查询生产领料
export function summaryByStoreAPI (params) {
  return request({
    url: '/api/system/produce/report/listPickingSummaryByStore',
    method: 'get',
    params
  })
}
// 部门汇总查询生产领料
export function summaryByDeptAPI (params) {
  return request({
    url: '/api/system/produce/report/listPickingSummaryByDept',
    method: 'get',
    params
  })
}





// 仓库汇总查询生产领料
// export function  summaryByDeptAPI (data) {
//   return request({
//     url: '/api/system/produce/report/listPickingSummaryByStore',
//     method:'post',
//     data
//   })
// }

// 部门汇总查询生产领料
// export function summaryByDeptAPI (data) {
//   return request({
//     url: '/api/system/produce/report/listPickingSummaryByDept',
//     method:'post',
//     data
//   })
// }

