import request from '@/utils/request'

//商品价格方案手工价格   分页列表
export function priceListListAPI (params) {
  return request({
    url: '/api/system/goods/hand/list',
    method: 'get',
    params
  })
}
// 商品价格方案手工价格  新增和修改
export function priceListSaveOrUpdateAPI (data) {
  return request({
    url: '/api/system/goods/hand/saveOrUpdate',
    method: 'put',
    data
  })
}
//商品价格方案手工价格  详情
export function priceListDetailAPI (handId) {
  return request({
    url: '/api/system/goods/hand/getDetail?handId=' + handId,
    method: 'get'
  })
}

// 商品价格方案手工价格  删除
export function priceListDeleteAPI (handIds) {
  return request({
    url: '/api/system/goods/hand/delete',
    method: 'delete',
    data: handIds
  })
}
// 商品价格方案手工价格    清除手工价格
export function clearPriceCaseHandAPI () {
  return request({
    url: '/api/system/goods/hand/clearPriceCaseHand',
    method: 'put'
  })
}
