import request from '@/utils/request'

//门店客流量分析列表
export function passengerFlowListAPI(params) {
  return request({
    url: '/api/system/shop/base/passengerFlow/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店客流量分析列表  合计行
export function passengerFlowListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/base/passengerFlow/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店客流量分析列表 客单价
export function customerPriceAnalysisApi(data) {
  return request({
    url: '/api/system/shop/base/passengerFlow/customerPriceAnalysis',
    method: 'post',
    timeout: 30000,
    data
  })
}
