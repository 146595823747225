import request from '@/utils/request'

//获取业务参数配置生产参数   分页列表
export function produceListAPI (params) {
  return request({
    url: '/api/system/sysParam/getSysParamProduceConfig',
    method: 'get',
    params
  })
}
// 业务参数配置生产参数
export function produceAddUpdateAPI(data) {
  return request({
    url: '/api/system/sysParam/saveSysParamProduceConfig',
    method: 'put',
    data: data
  })
}