import request from '@/utils/request'

// 批量添加更新会员等级价格方案  新增
export function vipPriceAddAPI (data) {
  return request({
    url: '/api/system/vip/base/level/saveOrUpdatePriceCase',
    method: 'post',
    data
  })
}
//会员等级价格方案  详情
export function vipPriceDetailAPI (priceCaseId) {
  return request({
    url:
      '/api/system/vip/base/level/getPriceCaseDetail?priceCaseId=' +
      priceCaseId,
    method: 'get'
  })
}
