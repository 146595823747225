import request from '@/utils/request'

//短信营销   分页列表
export function sendListAPI (params) {
  return request({
    url: '/api/system/marketing/sms/send/list',
    method: 'get',
    params
  })
}
// 短信营销  新增
export function sendAddAPI (data) {
  return request({
    url: '/api/system/marketing/sms/send/save',
    method: 'post',
    data
  })
}
//短信营销  详情
export function sendDetailAPI (sendId) {
  return request({
    url: '/api/system/marketing/sms/send/getDetail?sendId=' + sendId,
    method: 'get'
  })
}
