import request from '@/utils/request'

// 生产部门仓库分页列表
export function produceStoreList (data) {
  return request({
    url: '/api/system/produce/store/list',
    method: 'POST',
    data
  })
}

// 新增生产部门仓库
export function produceStoreAdd (data) {
  return request({
    url: '/api/system/produce/store/save',
    method: 'post',
    data
  })
}

// 修改生产部门仓库
export function produceStoreUpdate (data) {
  return request({
    url: '/api/system/produce/store/update',
    method: 'put',
    data
  })
}

// 删除生产部门仓库
export function produceStoreRemove (data) {
  return request({
    url: '/api/system/produce/store/delete',
    method: 'delete',
    data
  })
}

// 获取生产部门仓库详情
export function produceStoreDetail (lineId) {
  return request({
    url: `/api/system/produce/store/getDetail?lineId=${lineId}`,
    method: 'get'
  })
}

// 批量生产部门仓库
export function updateBatchStoreInfo (data) {
  return request({
    url: 'api/system/produce/store/updateBatchStoreInfo',
    method: 'post',
    data
  })
}
