import request from '@/utils/request'

//销售趋势：（销售 退货）
export function saleReturnAPI (params) {
  return request({
    url: '/api/system/sale/analysis/saleTrend',
    method: 'get',
    params
  })
}
//销售分布（按商品类别 客户 部门 商品 业务员）
export function saleDistributionAPI (params) {
  return request({
    url: '/api/system/sale/analysis/saleDistribution',
    method: 'get',
    params
  })
}
//销售排行：（按商品类别 客户 部门 商品 业务员）
export function rankingAPI (params) {
  return request({
    url: '/api/system/sale/analysis/ranking',
    method: 'get',
    params
  })
}