import request from '@/utils/request'
//商户资料

// 获取服务公告列表
export function noticeList(params) {
  return request({
    url: "/api/system/tenant/notice/list",
    params
  });
}

// 新增服务公告
export function addNotice(data) {
  return request({
    url: "/api/system/tenant/notice/save",
    method: 'POST',
    data
  });
}

// 修改服务公告
export function updateNotice(data) {
  return request({
    url: "/api/system/tenant/notice/update",
    method: 'put',
    data
  });
}

// 获取服务公告
export function getNoticeDetail(noticeId) {
  return request({
    url: `/api/system/tenant/notice/getDetail?noticeId=${noticeId}`,
  });
}


// 删除服务公告
export function removeNotice(data) {
  return request({
    url: "/api/system/tenant/notice/remove",
    method: 'post',
    data
  });
}

// 批量还原公告
export function restoreNotice(noticeIds) {
  return request({
    url: "/api/system/tenant/notice/restoreNotice",
    method: 'post',
    data: noticeIds
  });
}

// 复制服务公告
export function copyNotice(noticeIds) {
  return request({
    url: "/api/system/tenant/notice/copyNotice",
    method: 'POST',
    data: noticeIds
  });
}

// 服务公告发布状态
export function noticeStatus(data) {
  return request({
    url: "/api/system/tenant/notice/noticeStatus",
    method: 'POST',
    data
  });
}

// 服务公告启用状态
export function isStopNotice(noticeIds) {
  return request({
    url: "/api/system/tenant/notice/updateStopStatus",
    method: 'POST',
    data: noticeIds
  });
}
