import request from '@/utils/request'

// 收入类别

//获取收入分类列表
export function listArCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/listArCategory',
    method: 'POST',
    data
  })
}
//获取收入类别 分类详情
export function getCategoryInfo(params) {
  return request({
    url: '/api/system/finance/rp/category/getCategoryInfo',
    method: 'get',
    params: params
  })
}

// add收入分类
export function addArCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/addArCategory',
    method: 'POST',
    data
  })
}
// 修改收入分类
export function modifyArCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/modifyArCategory',
    method: 'POST',
    data
  })
}
// 删除收入分类
export function deleteCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/deleteCategory',
    method: 'POST',
    data
  })
}

// ======
// 添加收入/支出分类列表
export function addTypeList(data) {
  return request({
    url: '/api/system/finance/rp/type/addType',
    method: 'POST',
    data
  })
}

// 修改收入/支出分类列表
export function modifyTypeList(data) {
  return request({
    url: '/api/system/finance/rp/type/modifyType',
    method: 'POST',
    data
  })
}

// 删除收入/支出分类列表
export function deleteCategoryList(data) {
  return request({
    url: '/api/system/finance/rp/type/deleteCategory',
    method: 'POST',
    data
  })
}

// 收入/支出分类列表
export function listRpType(data) {
  return request({
    url: '/api/system/finance/rp/type/listRpType',
    method: 'POST',
    data
  })
}

// 获取 收入/支出分类 详情
export function getRpTypeByRpTypeId(params) {
  return request({
    url: '/api/system/finance/rp/type/getRpTypeByRpTypeId',
    method: 'get',
    params: params
  })
}

//更新对账状态
export function updateReconStatus(params) {
  return request({
    url: params.url,
    method: 'post',
    data: {
      rpTypeIds: params.rpTypeIds,
      isStop: params.isStop,
    }
  })
}

//  支出类别
//获取支出分类列表
export function listApCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/listApCategory',
    method: 'POST',
    data
  })
}
// add支出分类
export function addApCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/addApCategory',
    method: 'POST',
    data
  })
}
// 修改支出分类
export function modifyApCategory(data) {
  return request({
    url: '/api/system/finance/rp/category/modifyApCategory',
    method: 'POST',
    data  
  })
}
