import request from '@/utils/request'
import moment from "moment";
import { getUid } from '@/utils/index.js'
import OSS from "ali-oss";

// const OSS = require('ali-oss');

//删除已上传的文件 (阿里云)
export const delOss = (filePath) => {
  return request({
    url: `/api/open/oss/file/delete?filePath=${filePath}`,
    method: 'get'
  })
}

//下载已上传的文件 (阿里云)
export const downloadOss = (filePath) => {
  return request({
    url: `/api/open/oss/file/fileDownload?filePath=${filePath}`,
    method: 'get',
  })
}


// 文件上传 OSS 获取参数
export const getALiyunOssConfig = (data) => {
  return request({
    url: '/api/open/oss/file/getALiyunOssConfig',
    method: 'post',
    data
  })
}

let client = null
let expiration = null
let fileHost = null
export const ossUpload = (
  file,
  {
    config: {
      useFileName = false,
      customName = ''
    } = {},
    onProgress = () => { },
    onCancel = () => false,
  } = {}
) => new Promise(async (success, fail) => {
  try {
    const ossFileName = getUid(32, true)
    // 超时前五分钟
    if (expiration && moment().diff(moment(expiration), 'minutes') > -5) {
      client = null;
    }
    if (!client || !expiration) {
      const { data } = await getALiyunOssConfig({ ossFileName });
      expiration = data.expiration
      fileHost = data.fileHost
      client = new OSS({
        bucket: data.bucketName, // bucket名称
        region: data.regionId, // 地域
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        stsToken: data.securityToken,
        expiration: data.expiration, // 有效期
      });
    }

    const fileArr = file.file.name.split('.');
    const fileType = fileArr.pop();
    const fileName = fileArr.join('.');
    const dir = `/${fileHost}/${moment().format('YYYY-MM-DD')}/`;
    const res = await client.multipartUpload(
      customName ? customName : `${dir}${useFileName ? fileName : ossFileName}.${fileType}`,
      file.file,
      {
        parallel: 3, //并行上传的分片个数
        partSize: 1024 * 1024 * 2, // 分片大小
        // checkpoint: true, // 断点续传
        progress(p) { // 获取上传进度，上传进度为0 - 1， 1为上传100%
          try {
            if (onCancel()) {
              client.cancel();
            }
          } catch (error) {
          }
          onProgress(p * 100);
        }
      }
    )
    // const ossPath = res.res.requestUrls[0].split('?')[0];
    // 替换协议，统一使用 'https://'，否则 Android 无法显示图片
    const path = res.res.requestUrls[0].split('?')[0];
    const httpUrl = path;
    const httpsUrl = path.replace('http://', 'https://');

    success({
      dir, // 目录
      httpUrl, // aliurl
      httpsUrl, // aliurl
      fileType, // 文件类型
      fileOldName: file.file.name,// 原文件名
      fileUrl: decodeURIComponent(httpsUrl),
      fileSize: file.file.size, // 文件大小
      fileName: decodeURIComponent(httpUrl.split('/').pop()),  // 新文件名
    });
  } catch (error) {
    fail(error);
  }
})