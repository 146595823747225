import request from '@/utils/request'

// 系统升级日志分页列表
export const getLogList = (data) => request({ method: 'GET', url: '/api/system/log/list', params: data });

export const addLog = (data) => request({ method: 'POST', url: '/api/system/log/save', data });

export const updateLog = (data) => request({ method: 'PUT', url: '/api/system/log/update', data });

export const delLog = (data) => request({ method: 'DELETE', url: '/api/system/log/delete', data });

export const getAllLogList = () => request({ method: 'POST', url: '/api/system/log/all' });

export const releaseLog = (data) => request({ method: 'PUT', url: '/api/system/log/release', data });

export const copyLog = (data) => request({ method: 'POST', url: '/api/system/log/copy', data });

export const getUpgradeProductVerNo = (data) => request({ method: 'GET', url: '/api/system/log/getUpgradeProductVerNo', params: data });

