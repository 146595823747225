import request from '@/utils/request' //门店调入

//更新门店调货单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/shop/bill/in/updateBillStatus',
    method: 'post',
    data
  })
}
//修改门店调货单据
export function updateOrder (data) {
  return request({
    url: '/api/system/shop/bill/in/update',
    method: 'PUT',
    data
  })
}

//获取门店调货单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/shop/bill/in/getDetail?billId=${billId}`
  })
}

//新增门店调货单据
export function addOrder (data) {
  return request({
    url: '/api/system/shop/bill/in/save',
    method: 'POST',
    data
  })
}

// 门店调货列表
export function listOrder (query) {
  return request({
    url: '/api/system/shop/bill/in/list',
    method: 'get',
    params: query
  })
}
