import request from '@/utils/request'

// 生产组分页列表
export function produceGroupList(params) {
  return request({
    url: '/api/system/produce/bas/group/list',
    method: 'get',
    params
  })
}
// 新增生产组
export function produceGroupAdd(data) {
  return request({
    url: '/api/system/produce/bas/group/save',
    method: 'post',
    data
  })
}
// 修改生产组
export function produceGroupUpdate(data) {
  return request({
    url: '/api/system/produce/bas/group/update',
    method: 'put',
    data
  })
}
// 删除生产组
export function produceGroupRemove(produceGroupId) {
  return request({
    url:
      '/api/system/produce/bas/group/remove',
    method: 'delete',
    data: {
      produceGroupId
    }
  })
}

// 查询生产组商品列表
export function produceGroupGoodsList(params) {
  return request({
    url: '/api/system/produce/bas/group/goods/list',
    method: 'get',
    params
  })
}
// 添加生产组商品
export function produceGroupGoodsAdd(data) {
  return request({
    url: '/api/system/produce/bas/group/goods/update',
    method: 'PUT',
    data
  })
}
// 删除生产组商品
export function produceGroupGoodsRemove(produceGroupGoodsId) {
  return request({
    url:
      '/api/system/produce/bas/group/goods/remove',
    method: 'delete',
    data: {
      goodsId: produceGroupGoodsId
    }
  })
}

// 生产组商品查询
export function listProduceGroupGoods(params) {
  return request({
    url: '/api/system/produce/bas/group/goods/listProduceGroupGoods',
    method: 'get',
    params
  })
}
