import request from '@/utils/request'

//销售日报  分页列表
export function saleDailyReportAPI (params) {
  return request({
    url: '/api/system/sale/analysis/saleDailyReport',
    method: 'get',
    params
  })
}

//销售日报年份  分页列表
export function saleDailyReporYeartAPI (params) {
  return request({
    url: '/api/system/saas/tenant/listReportYear',
    method: 'get',
    params
  })
}