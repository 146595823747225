import request from '@/utils/request'

//获取业务参数配置进销存参数    分页列表
export function stockListAPI (params) {
  return request({
    url: '/api/system/sysParam/getSysParamSalesStockConfig',
    method: 'get',
    params
  })
}
//业务参数配置进销存参数
export function stockAddUpdateAPI(data) {
  return request({
    url: '/api/system/sysParam/saveSysParamSalesStockConfig',
    method: 'put',
    data: data
  })
}