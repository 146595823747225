import request from '@/utils/request'

// 产品菜单列表树
export function accountOrderSave (params) {
  return request({
    url: '/api/system/tenant/account/order/save',
    method: 'post',
    params
  })
}

// 获取支付方式
export function payConfigList (data) {
  return request({
    url: '/api/system/tenant/pay/config/list',
    method: 'get',
    data
  })
}

// 产品新增，类型保存草稿/新增数据
export function orderSaveSubmit (data) {
  return request({
    url: '/api/system/tenant/account/order/purchaseAccountProductOrder',
    method: 'post',
    data
  })
}

// 获取所有产品
export function getOrderList (data) {
  return request({
    url: '/api/system/tenant/product/listAllSaasProduct',
    method: 'get',
    params: data
  })
}

// 审核
export function setAudit (data) {
  return request({
    url: '/api/system/tenant/account/order/updateOrderStatus',
    method: 'put',
    data
  })
}

// 获取详情
export const getOrderDetail = params => {
  return request({
    url: '/api/system/tenant/account/order/getOrderItems',
    method: 'get',
    params
  })
}

// 计算选中的产品订单项金额
export function computeProductItemMoney (data) {
  return request({
    url: '/api/system/tenant/account/order/computeProductItemMoney',
    method: 'post',
    data
  })
}
