import request from '@/utils/request'

// 查询公告列表
export function noticeList(query) {
  return request({
    url: '/api/system/notice/list',
    method: 'get',
    params: query
  })
}

// 查询公告详细
export function getNoticeDetail(noticeId) {
  return request({
    url: '/api/system/notice/' + noticeId,
    method: 'get'
  })
}

// 新增公告
export function addNotice(data) {
  return request({
    url: '/api/system/notice',
    method: 'post',
    data
  })
}

// 修改公告
export function updateNotice(data) {
  return request({
    url: '/api/system/notice',
    method: 'put',
    data
  })
}

// 删除公告
export function removeNotice(data) {
  return request({
    url: '/api/system/notice/remove',
    method: 'post',
    data
  })
}
// 批量还原公告
export function restoreNotice(data) {
  return request({
    url: "/api/system/notice/restoreNotice",
    method: 'post',
    data
  });
}

// 服务公告发布状态
export function noticeStatus(data) {
  return request({
    url: "/api/system/notice/noticeStatus",
    method: 'POST',
    data
  });
}

// 服务公告启用状态
export function isStopNotice(data) {
  return request({
    url: "/api/system/notice/updateStopStatus",
    method: 'POST',
    data
  });
}