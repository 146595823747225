import request from '@/utils/request'

// 系统提醒设置分页列表
export const getRemindList = (data) => request({
  url: '/api/system/remind/config/list',
  method: 'get',
  params: data
})


// 新增
export const addRemind = (data) => request({
  url: '/api/system/remind/config/save',
  method: 'post',
  data
})

// 编辑
export const updateRemind = (data) => request({
  url: '/api/system/remind/config/update',
  method: 'put',
  data
})

// 删除
export const delRemind = (data) => request({
  url: '/api/system/remind/config/delete',
  method: 'delete',
  data
})

// 启用/停用
export const startOrStop = (data) => request({
  url: `/api/system/remind/config/startOrStop`,
  method: 'post',
  data
})


