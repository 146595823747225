import request from '@/utils/request'

//仓库调拨汇总报表   分页列表
export function moveTotalListAPI (params) {
  return request({
    url: '/api/system/store/reportForms/transferSummary',
    method: 'get',
    params
  })
}
//仓库+类别调拨汇总
export function summaryByStoreGoodsCategory (params) {
  return request({
    url: '/api/system/store/reportForms/summaryByStoreGoodsCategory',
    method: 'get',
    params
  })
}
//按仓库，进行汇总 分页列表
export function summaryByStore (params) {
  return request({
    url: '/api/system/store/reportForms/summaryByStore',
    method: 'get',
    params
  })
}


export function sumTransferSummary (params) {
  return request({
    url: '/api/system/store/reportForms/sumTransferSummary',
    method: 'get',
    params
  })
}

//仓库商品可用库存查询   分页列表
export function goodsAvailableStock (params) {
  return request({
    url: '/api/system/store/reportForms/goodsAvailableStock',
    method: 'get',
    params
  })
}
//仓库商品进销存一览表   分页列表
export function goodsSalePreview (params) {
  return request({
    url: '/api/system/store/reportForms/goodsSalePreview',
    method: 'get',
    params
  })
}


//仓库调拨确认表   分页列表
export function transferConfirm (params) {
  return request({
    url: '/api/system/store/reportForms/transferConfirm',
    method: 'get',
    params
  })
}

//仓库调拨明细表
export function listTransferDetail (params) {
  return request({
    url: '/api/system/store/reportForms/listTransferDetail',
    method: 'get',
    params
  })
}

//仓库调拨明细表
export function sumListTransferDetail (params) {
  return request({
    url: '/api/system/store/reportForms/sumListTransferDetail',
    method: 'get',
    params
  })
}
