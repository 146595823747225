import request from '@/utils/request'

//标签模板   分页列表
export function labelTemplateListAPI (params) {
  return request({
    url: '/api/system/goods/template/list',
    method: 'get',
    params
  })
}
// 标签模板  新增
export function labelTemplateAddAPI (data) {
  return request({
    url: '/api/system/goods/template/save',
    method: 'post',
    data
  })
}
//标签模板  详情
export function labelTemplateDetailAPI (lableTemplateId) {
  return request({
    url:
      '/api/system/goods/template/getDetail?lableTemplateId=' + lableTemplateId,
    method: 'get'
  })
}
// 标签模板   修改
export function labelTemplateUpdateAPI (data) {
  return request({
    url: '/api/system/goods/template/update',
    method: 'put',
    data
  })
}
// 标签模板  删除
export function labelTemplateDeleteAPI (lableTemplateIds) {
  return request({
    url: '/api/system/goods/template/delete',
    method: 'delete',
    data: lableTemplateIds
  })
}

//标签模板   复制
export function labelTemplateCopyAPI (lableTemplateId) {
  return request({
    url:
      '/api/system/goods/template/copyGoodsLableTemplate?lableTemplateId=' +
      lableTemplateId,
    method: 'get'
  })
}
