import request from '@/utils/request'

// 门店收银交班记录分页列表
export function getDetail(query) {
  return request({
    url: '/api/system/shop/index/list',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店收银交班记录分页列表  合计行
export function getDetailSummary(query) {
  return request({
    url: '/api/system/shop/index/listSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
