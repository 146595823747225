import request from '@/utils/request'

//获取所有仓库
export function allStoreList(params) {
  return request({
    url: '/api/system/goods/store/getAllStore',
    method: 'get',
    params
  })
}

//根据商户的名称获取商户所有仓库
export function getAllStoreByName(query) {
  return request({
    url: '/api/system/goods/store/getAllStoreByName',
    method: 'get',
    params: query
  })
}

// 商品每日库存查询
export function listBookStatisticsByStoreGoodsDay(data) {
  return request({
    url: '/api/system/store/statistics/listBookStatisticsByStoreGoodsDay',
    method: 'POST',
    data
  })
}

// 商品每日库存查询 合计
export function sumListBookStatisticsByStoreGoodsDay(data) {
  return request({
    url: '/api/system/store/statistics/sumListBookStatisticsByStoreGoodsDay',
    method: 'POST',
    data
  })
}
// 商品每日库存查询
export function listBookStatisticsByStoreGoods(data) {
  return request({
    url: '/api/system/store/statistics/listBookStatisticsByStoreGoods',
    method: 'POST',
    data
  })
}

// 商品每日库存查询 合计
export function sumListBookStatisticsByStoreGoods(data) {
  return request({
    url: '/api/system/store/statistics/sumListBookStatisticsByStoreGoods',
    method: 'POST',
    data
  })
}
