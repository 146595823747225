import request from "@/utils/request";
import { parseStrEmpty } from "@/utils/baling";

// 查询员工列表
export function listEmployee(query) {
  return request({
    url: "/api/system/employee/list",
    method: "get",
    params: query,
  });
}

// 查询员工详细
export function getEmployee(employeeId) {
  return request({
    url: "/api/system/employee/" + parseStrEmpty(employeeId),
    method: "get",
  });
}

// 新增员工
export function addEmployee(data) {
  return request({
    url: "/api/system/employee",
    method: "post",
    data,
  });
}

// 员工审核
export function auditEmployee(data) {
  return request({
    url: "/api/system/employee/auditEmployee",
    method: "post",
    data: data,
  });
}

// 修改员工
export function updateEmployee(data) {
  return request({
    url: "/api/system/employee",
    method: "put",
    data,
  });
}

// 删除员工
// export function delEmployee(employeeId) {
//   return request({
//     url: "/api/system/employee/" + employeeId,
//     method: "delete",
//   });
// }

// 删除员工
export function delEmployee(employeeId) {
  return request({
    url: '/api/system/employee/remove',
    method: 'delete',
    data: employeeId
  })
}

//还原员工
export function revertEmpoloyee(data) {
  return request({
    url: '/api/system/employee/revert',
    method: 'post',
    data
  })
}


// 员工状态修改
export function changeUserStatus(employeeIds, status) {
  const data = {
    employeeIds,
    status,
  };
  return request({
    url: "/api/system/employee/changeStatus",
    method: "put",
    data: data,
  });
}



// 查询授权角色
export function getAuthRole(employeeId) {
  return request({
    url: "/api/system/employee/authRole/" + employeeId,
    method: "get",
  });
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: "/api/system/employee/authRole",
    method: "put",
    data,
  });
}


// 获取所有商户员工
export function getTenantEmployee(query) {
  return request({
    url: "/api/system/employee/getTenantEmployee",
    method: "get",
    params: query,
  });
}

// 根据姓名获取所有商户员工
export function getAllTenantEmployeeByName(data) {
  return request({
    url: "/api/system/employee/getAllTenantEmployeeByName",
    method: "post",
    data: data,
  });
}

//查询员工列表
export function allEmployeeList() {
  return request({
    url: "/api/system/employee/getTenantEmployee"
  });
}

//还原被删除员工
export function revertAPI(data) {
  return request({
    url: '/api/system/employee/revert',
    method: 'post',
    data: data
  })
}

// 初始化用户密码
export function uinitPasswordAPI(data) {
  return request({
    url: "/api/system/user/initPassword",
    method: "put",
    data: data,
  });
}
