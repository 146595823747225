import request from '@/utils/request'

// 获取配送路线列表
export function queryDeliverLineList (data) {
  return request({
    url: '/api/system/line/queryDeliverLineList',
    method: 'POST',
    data
  })
}

// 新增配送路线
export function deliverLineUpdate (data) {
  return request({
    url: '/api/system/line/update',
    method: 'PUT',
    data
  })
}
// 获取配送对象
export function queryDeliverLineDistributionList (data) {
  return request({
    url: '/api/system/line/queryDeliverLineDistributionList',
    method: 'POST',
    data
  })
}

// 删除配送路线
export function deliverLineDelete (data) {
  return request({
    url: '/api/system/line/delete',
    method: 'DELETE',
    data
  })
}

// 右边 加添加
export function updateDeliverLineDistributionInfo (deliverInfo) {
  return request({
    url: '/api/system/line/updateDeliverLineDistributionInfo',
    method: 'POST',
    data: { deliverInfo }
  })
}

//路线全部仓库
export function getAllStoreList (params) {
  return request({
    url: '/api/system/goods/store/getAllStoreList',
    method: 'GET',
    params
  })
}
