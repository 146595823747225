import request from '@/utils/request'

//支付通道   分页列表
export function payChannelListAPI (params) {
  return request({
    url: '/api/system/shop/channel/list',
    method: 'get',
    params
  })
}
// 支付通道  新增
export function payChannelAddAPI (data) {
  return request({
    url: '/api/system/shop/channel/save',
    method: 'post',
    data
  })
}
//支付通道  详情
export function payChannelDetailAPI (payChannelId) {
  return request({
    url: '/api/system/shop/channel/getDetail?payChannelId=' + payChannelId,
    method: 'get'
  })
}
// 支付通道   修改
export function payChannelUpdateAPI (data) {
  return request({
    url: '/api/system/shop/channel/update',
    method: 'put',
    data
  })
}
// 支付通道  删除
export function payChannelDeleteAPI (payChannelIds) {
  return request({
    url: '/api/system/shop/channel/remove',
    method: 'delete',
    data: payChannelIds
  })
}

// 支付通道  启用/停用
export function payChannelIsStopAPI (data) {
  return request({
    url: '/api/system/shop/channel/isStop',
    method: 'post',
    data
  })
}

// 支付通道   复制
export function payChannelCopyAPI (payChannelIds) {
  return request({
    url: '/api/system/shop/channel/copyShopPayChannel',
    method: 'post',
    data: payChannelIds
  })
}

// 还原支付通道
export function restorePayChannel (data) {
  return request({
    url: '/api/system/shop/channel/restorePayChannel',
    method: 'post',
    data
  })
}
