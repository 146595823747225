import request from '@/utils/request'

// 产品树目录(包括产品和版本)
export function listTree(query) {
  return request({
    url: '/api/system/tenant/product/listTree',
    method: 'get',
    params: query
  })
}

// 查询产品信息详细
export function getProduct(productId) {
  return request({
    url: '/api/system/saas/product/' + productId,
    method: 'get'
  })
}

// 产品版本明细
export function getProductVersionDetail(versionId) {
  return request({
    url: '/api/system/tenant/product/getProductVersionDetail?versionId=' + versionId,
    method: 'get'
  })
}

// 新增产品信息
export function addProduct(data) {
  return request({
    url: '/api/system/saas/product',
    method: 'post',
    data: data
  })
}

// 修改产品信息
export function updateProduct(data) {
  return request({
    url: '/api/system/saas/product',
    method: 'put',
    data: data
  })
}

// 商户产品菜单权限同步
export function fillTenantProductMenu() {
  return request({
    url: '/api/system/product/menu/fillTenantProductMenu',
    method: 'put'
  })
}

// 删除产品信息
export function delProduct(productId) {
  return request({
    url: '/api/system/saas/product/' + productId,
    method: 'delete'
  })
}


// 产品状态修改
export function changeProductStatus(productId, status) {
  const data = {
    productId,
    status
  }
  return request({
    url: '/api/system/saas/product/changeStatus',
    method: 'put',
    data: data
  })
}

//获取产品列表
export function ProductListAPI(params) {
  return request({
    url: '/api/system/saas/product/listProduct',
    method: 'get',
    params
  })
}

// 加载对应产品菜单列表树
export function productMenuTreeSelect(productId) {
  return request({
    url: '/api/system/product/menu/productMenuTreeSelect?productId=' + productId,
    method: 'get'
  })
}


// 产品关联菜单
export function relationMenu(data) {
  return request({
    url: '/api/system/product/menu/relationMenu',
    method: 'post',
    data: data
  })
}
