import request from '@/utils/request'

//门店销售对比   分页列表
export function listSaleContrastAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/contrast/listSaleContrast',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店销售对比   合计行
export function listSaleContrastSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/contrast/listSaleContrastSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
