import request from '@/utils/request'

//短信套餐   分页列表
export function smsPackageListAPI (params) {
  return request({
    url: '/api/system/saas/product/sms/package/list',
    method: 'get',
    params
  })
}
// 短信套餐  新增
export function smsPackageAddAPI (data) {
  return request({
    url: '/api/system/saas/product/sms/package/save',
    method: 'post',
    data
  })
}
//短信套餐  详情
export function smsPackageDetailAPI (packageId) {
  return request({
    url:
      '/api/system/saas/product/sms/package/getDetail?packageId=' + packageId,
    method: 'get'
  })
}
// 短信套餐   修改
export function smsPackageUpdateAPI (data) {
  return request({
    url: '/api/system/saas/product/sms/package/update',
    method: 'put',
    data
  })
}

// 短信套餐  删除
export function smsPackageDeleteAPI (packageIds) {
  return request({
    url: '/api/system/saas/product/sms/package/delete',
    method: 'put',
    data: { packageIds }
  })
}
// 短信套餐    启用状态
export function smsPackageIsStopAPI (data) {
  return request({
    url: '/api/system/saas/product/sms/package/isStop',
    method: 'put',
    data
  })
}
// 短信套餐  付款码
export function smsOrderPay (data) {
  return request({
    url: '/api/system/marketing/sms/buy/order/orderPay',
    method: 'post',
    data
  })
}
