import request from '@/utils/request'

//销售排名分页列表   分页列表
export function saleRankListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售排名分页列表   合计
export function saleRankListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
