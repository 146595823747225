import request from '@/utils/request'
// 查询产品分货明细列表
export function listDetails (params) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/report/listDetails',
    method: 'get',
    params
  })
}
// 产品分货按生产工厂汇总查询
export function listDistributeByProduceDept (params) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/report/listDistributeByProduceDept',
    method: 'get',
    params
  })
}

// 产品分货按商品汇总查询
export function listDistributeByGoods (params) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/report/listDistributeByGoods',
    method: 'get',
    params
  })
}
