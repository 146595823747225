import request from '@/utils/request'

// 产品入库分页列表
export function inStoreListAPI(params) {
  return request({
    url: '/api/system/produce/bill/storage/list',
    method: 'get',
    params
  })
}
// 新增产品入库
export function inStoreAddAPI(data) {
  return request({
    url: '/api/system/produce/bill/storage/save',
    method: 'post',
    data
  })
}
//获取产品入库详情
export function inStoreDetailAPI(billId) {
  return request({
    url: '/api/system/produce/bill/storage/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 修改产品入库
export function inStoreUpdateAPI(data) {
  return request({
    url: '/api/system/produce/bill/storage/update',
    method: 'put',
    data
  })
}
// 更改状态
export function inStoreUpdateStatusAPI(data) {
  return request({
    url: '/api/system/produce/bill/storage/updateBillStatus',
    method: 'post',
    data
  })
}
// 更新生产质检状态
export function updateProductCheckStatusAPI(data) {
  return request({
    url: '/api/system/produce/bill/storage/updateProductCheckStatus',
    method: 'post',
    data
  })
}
// 更新成品质检状态
export function updateQualityCheckStatusAPI(data) {
  return request({
    url: '/api/system/produce/bill/storage/updateQualityCheckStatus',
    method: 'post',
    data
  })
}
// 更新入库状态
export function updateInStoreStatusAPI(data) {
  return request({
    url: '/api/system/produce/bill/storage/updateInStoreStatus',
    method: 'post',
    data
  })
}

//   入库单相关单据列表
export function listImportBillInfo(billId) {
  return request({
    url: '/api/system/produce/bill/storage/listImportBillInfo',
    method: 'post',
    data: { billId }
  })
}