import request from '@/utils/request'

// 门店退货分析明细
export function listReturnAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listReturnAnalysisDetail',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
// 门店退货分析明细  合计行
export function listReturnSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listReturnAnalysisDetailSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店商品退货查询
export function listGoodsReturnAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listGoodsReturnQuery',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
//门店商品退货查询  合计行
export function listGoodsReturnSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listGoodsReturnQuerySummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店退货汇总（按门店）
export function listShopReturnQuery(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listShopReturnQuery',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
//门店退货汇总（按门店）  合计行
export function listShopReturnQuerySummary(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listShopReturnQuerySummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

//门店退货汇总（按类别）
export function listShopCategoryReturnQuery(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listShopCategoryReturnQuery',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
//门店退货汇总（按类别）  合计行
export function listShopCategoryReturnQuerySummary(query) {
  return request({
    url: '/api/system/shop/report/sale/analysis/listShopCategoryReturnQuerySummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
