import request from '@/utils/request'

//促销分析   分页列表
export function buyAnalyseListAPI (params) {
  return request({
    url: '/api/system/marketing/base/promotion/analysis/list',
    method: 'get',
    params
  })
}

//促销分析   分页合计
export function buyAnalyseListAPISummary (params) {
  return request({
    url: '/api/system/marketing/base/promotion/analysis/listSummary',
    method: 'get',
    params
  })
}




