import request from '@/utils/request'

// 系统字典类型列表
export function listSystem(query) {
  return request({
    url: '/api/system/dict/type/listSystem',
    method: 'get',
    params: query
  })
}

// 业务字典类型列表
export function listBusiness(query) {
  return request({
    url: '/api/system/dict/type/listBusiness',
    method: 'get',
    params: query
  })
}

// 查询字典类型详细
export function getType(dictId) {
  return request({
    url: '/api/system/dict/type/' + dictId,
    method: 'get'
  })
}

// 新增字典类型
export function addType(data) {
  return request({
    url: '/api/system/dict/type',
    method: 'post',
    data: data
  })
}

// 修改字典类型
export function updateType(data) {
  return request({
    url: '/api/system/dict/type',
    method: 'put',
    data: data
  })
}

// 删除字典类型
export function delType(data) {
  return request({
    url: '/api/system/dict/type/delete',
    method: 'delete',
    data
  })
}

// 刷新字典缓存
export function refreshCache() {
  return request({
    url: '/api/system/dict/type/refreshCache',
    method: 'delete'
  })
}

// 获取字典选择框列表
export function optionselect() {
  return request({
    url: '/api/system/dict/type/optionSelect',
    method: 'get'
  })
}