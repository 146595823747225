import request from '@/utils/request'

// 批量添加更新分店价格方案  新增
export function shopPriceAddAPI (data) {
  return request({
    url: '/api/system/shop/base/info/saveOrUpdatePriceCase',
    method: 'post',
    data
  })
}
//分店价格方案  详情
export function shopPriceDetailAPI (priceCaseId) {
  return request({
    url:
      '/api/system/shop/base/info/getPriceCaseDetail?priceCaseId=' +
      priceCaseId,
    method: 'get'
  })
}
