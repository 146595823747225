import request from '@/utils/request'

const urlPrefix = ''
const urlAddress = {
  inStoreList: urlPrefix + '/api/system/purchase/store/list',
  returnList: urlPrefix + '/api/system/purchase/return/list',
  purchaseList: urlPrefix + '/api/system/shop/bill/purchase/list'
}

//更新单据状态
export function updateInstoreStatus(billIds, billStatus, url, billType) {
  return request({
    url,
    method: 'post',
    data: {
      billIds,
      billStatus,
      billType
    }
  })
}

// 单据分页列表
export function listBill(query, url) {
  return request({
    url,
    method: 'get',
    params: query
  })
}

//获取单据详情
export function getDetailBill(billId, url) {
  return request({
    url: `${url}?billId=${billId}`
  })
}

//新增单据
export function addBill(data, url) {
  return request({
    url,
    method: 'POST',
    data
  })
}


//新增裱花盘点
export function addDecorationCheckBill(data) {
  return request({
    url: '/api/system/decoration/bill/check/save',
    method: 'POST',
    data
  })
}

//新增现烤盘点
export function addFreshlyCheckBill(data) {
  return request({
    url: '/api/system/freshly/baked/bill/check/save',
    method: 'POST',
    data
  })
}
//新增单据
export function addStoreCheckBill(data) {
  return request({
    url: '/api/system/store/check/save',
    method: 'POST',
    data
  })
}
//新增门店单据
export function addShopCheckBill(data) {
  return request({
    url: '/api/system/shop/bill/check/save',
    method: 'POST',
    data
  })
}

//修改单据
export function updateBill(data, url) {
  console.log('保存 ==== ', url)
  return request({
    url,
    method: 'PUT',
    data
  })
}

//引入单据列表
export function getSourceBill(url, query) {
  return request({
    url,
    params: query
  })
}

//获取商品当前库存数
export function getGoodsFinalQty(data) {
  return request({
    url: '/api/system/store/check/getGoodsFinalQty',
    method: 'post',
    data
  })
}

export function httpInStoreList(params) {
  return request({
    url: urlAddress.inStoreList,
    method: 'get',
    params: params
  })
}

export function httpReturnList(params) {
  return request({
    url: urlAddress.returnList,
    method: 'get',
    params: params
  })
}
export function httpPurchaseListList(params) {
  return request({
    url: urlAddress.purchaseList,
    method: 'get',
    params
  })
}
export function surplusList(params) {
  return request({
    url: '/api/system/store/bill/inventory/surplus/list',
    method: 'get',
    params
  })
}
export function lossList(params) {
  return request({
    url: '/api/system/store/bill/inventory/loss/list',
    method: 'get',
    params
  })
}
// 
export function listSourceBill(query) {
  return request({
    url: '/api/system/source/bill/listSourceBill',
    method: 'get',
    params: query
  })
}

export function listSourceBillFranchise(query) {
  return request({
    url: '/api/system/franchise/source/bill/listSourceBill',
    method: 'get',
    params: query
  })
}

