import request from '@/utils/request'

//会员参数  分页列表
export function vipParamListAPI (params) {
  return request({
    url: '/api/system/shop/config/getShopVipConfig',
    method: 'get',
    params
  })
}

// 新增/更新配置会员参数设置
export function saveOrUpdateVipAPI (data) {
  return request({
    url: '/api/system/shop/config/saveShopVipConfig',
    method: 'post',
    data
  })
}


// 充值会员积分归零
export function resettingVIPAPI (data) {
  return request({
    url: '/api/system/shop/config/resettingVIPIntegral',
    method: 'post',
    data
  })
}