import request from '@/utils/request'

//价签   分页列表
export function labelPrintListAPI (params) {
  return request({
    url: '/api/system/goods/print/list',
    method: 'get',
    params
  })
}
// 价签  新增修改同一个
export function labelPrintAddAPI (data) {
  return request({
    url: '/api/system/goods/print/saveOrUpdate',
    method: 'post',
    data
  })
}

// 价签  删除
export function labelPrintDeleteAPI (data) {
  return request({
    url: '/api/system/goods/print/remove',
    method: 'delete',
    data
  })
}

// 价签    打印
export function labelPrintAPI (data) {
  return request({
    url: '/api/system/goods/print/print',
    method: 'post',
    data
  })
}
