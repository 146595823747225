import request from '@/utils/request'


//总库存安全标准分页列表
export function allAlertListAPI (params) {
  return request({
    url: '/api/system/store/earlyWarning/totalEarlyWarningList',
    method: 'get',
    params
  })
}
//库存安全标准分页列表
export function alertListAPI (params) {
  return request({
    url: '/api/system/store/earlyWarning/earlyWarningList',
    method: 'get',
    params
  })
}

