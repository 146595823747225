import request from '@/utils/request'

// 生产班次分页列表
export function produceOrderList (params) {
  return request({
    url: '/api/system/produce/bas/order/list',
    method: 'get',
    params
  })
}
// 新增生产班次
export function produceOrderBatchSave (data) {
  return request({
    url: '/api/system/produce/bas/order/batchSave',
    method: 'post',
    data
  })
}

// 生产班次全部
export function queryProduceBasOrderList (params) {
  return request({
    url: 'api/system/produce/bas/order/queryProduceBasOrderList',
    method: 'get',
    params
  })
}
