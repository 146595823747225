import request from '@/utils/request'

//门店pos图片  分页列表
export function posImageListAPI (params) {
  return request({
    url: '/api/system/shop/base/image/list',
    method: 'get',
    params
  })
}
// 门店pos图片  新增
export function posImageAddAPI (data) {
  return request({
    url: '/api/system/shop/base/image/save',
    method: 'post',
    data
  })
}
//门店pos图片 详情
export function posImageDetailAPI (posImageId) {
  return request({
    url: '/api/system/shop/base/image/getDetail?posImageId=' + posImageId,
    method: 'get'
  })
}
// 门店pos图片  修改
export function posImageUpdateAPI (data) {
  return request({
    url: '/api/system/shop/base/image/update',
    method: 'put',
    data
  })
}

// 门店pos图片 启用/停用
export function posImageIsStopAPI (data) {
  return request({
    url: '/api/system/shop/base/image/isStop',
    method: 'post',
    data
  })
}
// 门店pos图片   删除
export function posImageRemoveAPI (data) {
  return request({
    url: '/api/system/shop/base/image/remove',
    method: 'delete',
    data
  })
}

// 门店pos图片 还原门店POS图片
export function restoreShopPosImage (data) {
  return request({
    url: '/api/system/shop/base/image/restoreShopPosImage',
    method: 'post',
    data
  })
}
