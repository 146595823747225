import request from '@/utils/request'

// 配方替代分页列表
export function insteadList(params) {
  return request({
    url: '/api/system/produce/bill/bom/usable/material/list',
    method: 'get',
    params
  })
}
// 新增配方替代
export function insteadBatchSave(data) {
  return request({
    url: '/api/system/produce/bill/bom/usable/material/batchSave',
    method: 'post',
    data
  })
}
