import request from '@/utils/request'

//会员营销生日有礼   分页列表
export function birthdayGiftListAPI (params) {
  return request({
    url: '/api/system/marketing/vip/birthday/gift/list',
    method: 'get',
    params
  })
}
// 会员营销生日有礼  新增
export function birthdayGiftAddAPI (data) {
  return request({
    url: '/api/system/marketing/vip/birthday/gift/save',
    method: 'post',
    data
  })
}
//会员营销生日有礼  详情
export function birthdayGiftDetailAPI (billId) {
  return request({
    url: '/api/system/marketing/vip/birthday/gift/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 会员营销生日有礼   修改
export function birthdayGiftUpdateAPI (data) {
  return request({
    url: '/api/system/marketing/vip/birthday/gift/update',
    method: 'put',
    data
  })
}

// 会员营销生日有礼  启用/停用
export function birthdayGiftIsStopAPI (data) {
  return request({
    url: '/api/system/marketing/vip/birthday/gift/isStop',
    method: 'PUT',
    data
  })
}

//会员营销生日有礼   更改状态
export function birthdayGiftUpdateStatusAPI (data) {
  return request({
    url: '/api/system/marketing/vip/birthday/gift/updateStatus',
    method: 'post',
    data
  })
}
