import request from '@/utils/request'

//获取所有供应商列表
export function allPartnerList() {
    return request({
      url: '/api/system/partner/supplier/getAllSupplier',
    })
}

//获取所有客户列表
export function allCustomerList() {
  return request({
    url: '/api/system/partner/getAllCustomer',
  })
}

// 根据客户名称获取获取所有客户、供应商
export function getAllPartnerByName(data) {
  return request({
    url: "/api/system/partner/getAllPartnerByName",
    method: "post",
    data: data,
  });
}