import request from '@/utils/request'

//获取模板列表
export function getReceiptFormat(url) {
  return request({
    url
  })
}

//自定义小票格式

//获取收银小票模板列表
export function getCashierReceiptFormat() {
  return request({
    url: '/api/system/shop/format/list'
  })
}

//获取厨打小票模板列表
export function getKitchenReceiptFormat() {
  return request({
    url: '/api/system/shop/cook/format/list'
  })
}

//获取订单小票模板列表
export function getOrderMakeReceiptFormat() {
  return request({
    url: '/api/system/mall/order/format/list'
  })
}

//获取配送小票模板列表
export function getDeliveryReceiptFormat() {
  return request({
    url: '/api/system/shop/delivery/format/list'
  })
}

//新增收银小票模板
export function saveCashierReceiptFormat(data) {
  return request({
    url: "/api/system/shop/format/save",
    method: 'POST',
    data
  })
}

//新增厨打小票模板
export function saveKitchenReceiptFormat(data) {
  return request({
    url: "/api/system/shop/cook/format/save",
    method: 'POST',
    data
  })
}

//新增订单小票模板
export function saveOrderMakeReceiptFormat(data) {
  return request({
    url: "/api/system/mall/order/format/save",
    method: 'POST',
    data
  })
}

//新增配送小票模板
export function saveDeliveryReceiptFormat(data) {
  return request({
    url: "/api/system/shop/delivery/format/save",
    method: 'POST',
    data
  })
}

//修改收银小票模板
export function updateCashierReceiptFormat(data) {
  return request({
    url: "/api/system/shop/format/update",
    method: 'POST',
    data
  })
}

//修改厨打小票模板
export function updateKitchenReceiptFormat(data) {
  return request({
    url: "/api/system/shop/cook/format/update",
    method: 'POST',
    data
  })
}

//修改订单小票模板
export function updateOrderMakeReceiptFormat(data) {
  return request({
    url: "/api/system/mall/order/format/update",
    method: 'POST',
    data
  })
}

//修改配送小票模板
export function updateDeliveryReceiptFormat(data) {
  return request({
    url: "/api/system/shop/delivery/format/update",
    method: 'POST',
    data
  })
}

//删除收银小票模板
export function delCashierReceiptFormat(formatIds) {
  return request({
    url: "/api/system/shop/format/remove",
    method: 'DELETE',
    data: formatIds
  })
}

//删除厨打小票模板
export function delKitchenReceiptFormat(formatIds) {
  return request({
    url: "/api/system/shop/cook/format/remove",
    method: 'DELETE',
    data: formatIds
  })
}

//删除订单小票模板
export function delOrderMakeReceiptFormat(formatIds) {
  return request({
    url: "/api/system/mall/order/format/remove",
    method: 'DELETE',
    data: formatIds
  })
}

//删除配送小票模板
export function delDeliveryReceiptFormat(formatIds) {
  return request({
    url: "/api/system/shop/delivery/format/remove",
    method: 'DELETE',
    data: formatIds
  })
}

//启用收银小票模板
export function isStopCashierReceiptFormat(formatId) {
  return request({
    url: "/api/system/shop/format/isStop",
    params: {
      formatId
    }
  })
}

//启用厨打小票模板
export function isStopKitchenReceiptFormat(formatId) {
  return request({
    url: "/api/system/shop/cook/format/isStop",
    params: {
      formatId
    }
  })
}

//启用订单小票模板
export function isStopOrderMakeReceiptFormat(formatId) {
  return request({
    url: "/api/system/mall/order/format/isStop",
    params: {
      formatId
    }
  })
}

//启用配送小票模板
export function isStopDeliveryReceiptFormat(formatId) {
  return request({
    url: "/api/system/shop/delivery/format/isStop",
    params: {
      formatId
    }
  })
}

//复制收银小票模板
export function copyCashierReceiptFormat(formatId) {
  return request({
    url: "/api/system/shop/format/copyShopPosBillFormat",
    params: {
      formatId
    }
  })
}

//复制厨打小票模板
export function copyKitchenReceiptFormat(formatId) {
  return request({
    url: "/api/system/shop/cook/format/copyShopPosBillFormat",
    params: {
      formatId
    }
  })
}

//复制订单小票模板
export function copyOrderMakeReceiptFormat(formatId) {
  return request({
    url: "/api/system/mall/order/format/copyShopPosBillFormat",
    params: {
      formatId
    }
  })
}

//复制配送小票模板
export function copyDeliveryReceiptFormat(formatId) {
  return request({
    url: "/api/system/shop/delivery/format/copyShopPosBillFormat",
    params: {
      formatId
    }
  })
}
