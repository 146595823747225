import request from '@/utils/request'

// 产品次品处理列表
export function listInferior(data) {
  return request({
    url: '/api/system/produce/inferior/listInferior',
    method: 'post',
    data
  })
}

// 仓库报损处理次品
export function processInferiorToStoreLoss(data) {
  return request({
    url: '/api/system/produce/inferior/processInferiorToStoreLoss',
    method: 'post',
    data
  })
}

// 仓库调拨处理次品
export function processInferiorToStoreAllot(data) {
  return request({
    url: '/api/system/produce/inferior/processInferiorToStoreAllot',
    method: 'post',
    data
  })
}