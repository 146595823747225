import request from '@/utils/request'

// 商户产品分页列表
export function productList(params) {
  return request({
    url: '/api/system/tenant/product/list',
    method: 'get',
    params
  })
}

// 商户产品查看明细分页列表
export function productDetailList(params) {
  return request({
    url: '/api/system/tenant/product/getProductBookDetail',
    method: 'get',
    params
  })
}
