import request from '@/utils/request'

// 门店原料订货列表
export function listOrder(query) {
  return request({
    url: '/api/system/shop/bill/material/order/list',
    method: 'get',
    params: query
  })
}

//获取门店原料订货详情
export function getOrderDetail(billId) {
  return request({
    url: '/api/system/shop/bill/material/order/getDetail',
    method: 'get',
    params: { billId }
  })
}

// 添加门店原料订货
export function addOrder(data) {
  return request({
    url: '/api/system/shop/bill/material/order/save',
    method: 'post',
    data: data
  })
}

// 修改门店原料订货
export function updateOrder(data) {
  return request({
    url: '/api/system/shop/bill/material/order/update',
    method: 'put',
    data: data
  })
}

// 修改审核状态、关闭、删除
export function updateOrderStatus(data) {
  return request({
    url: '/api/system/shop/bill/material/order/updateBillStatus',
    method: 'POST',
    data
  })
}

// 复制门店原料订货
export function copyShopBillMaterialOrder(data) {
  return request({
    url: '/api/system/shop/bill/material/order/copyShopBillMaterialOrder',
    method: 'POST',
    data
  })
}