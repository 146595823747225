import request from '@/utils/request'

// 新增/修改平台短信配置
export function handleMsgConfig(data) {
    return request({
      url: `/api/system/tenant/msg/config/saveOrUpdate`,
      method:"POST",
      data
    })
  }

// 获取平台短信配置
export function smsConfigDetail(smsConfigId) {
    return request({
      url: `/api/system/tenant/msg/config/getDetail?smsConfigId=${smsConfigId}`,
    })
  }

