import request from '@/utils/request'

//门店预定单分页列表   分页列表
export function orderListAPI(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店预定单分页列表   分页列表
export function o2oOrderListAPI(params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/order/getShopPredetermineOrderList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店预定单分页列表   合计行
export function orderListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店预定单分页列表   合计行
export function o2oOrderListSummaryAPI(params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/order/getShopPredetermineOrderListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//预订查询汇总（按日期）   分页列表
export function getShopPredetermineDateList(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineDateList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//预订查询汇总（按日期）   合计行
export function getShopPredetermineDateListSummary(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineDateListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//预订查询汇总（按门店）   分页列表
export function getShopPredetermineShopList(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineShopList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//预订查询汇总（按门店）   合计行
export function getShopPredetermineShopListSummary(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineShopListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//预订查询汇总（按商品）   分页列表
export function getShopPredetermineGoodsList(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineGoodsList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//预订查询汇总（按商品）   合计行
export function getShopPredetermineGoodsListSummary(params) {
  return request({
    url: '/api/system/shop/base/predetermineOrder/getShopPredetermineGoodsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
