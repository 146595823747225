import request from '@/utils/request'

// 门店报损列表
export function listOrder (query) {
  return request({
    url: '/api/system/shop/bill/scrap/list',
    method: 'get',
    params: query
  })
}

//更新门店报损单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/shop/bill/scrap/updateBillStatus',
    method: 'post',
    data
  })
}
//修改门店报损单据
export function updateOrder (data) {
  return request({
    url: '/api/system/shop/bill/scrap/update',
    method: 'PUT',
    data
  })
}

//获取门店报损单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/shop/bill/scrap/getDetail?billId=${billId}`
  })
}

//新增门店报损单据
export function addOrder (data) {
  return request({
    url: '/api/system/shop/bill/scrap/save',
    method: 'POST',
    data
  })
}

// 复制门店报废
export function copyShopScrap(data) {
  return request({
    url: '/api/system/shop/bill/scrap/copyShopScrap',
    method: 'POST',
    data
  })
}

