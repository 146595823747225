import request from '@/utils/request'

//oss对象储存

// oss对象储存分页列表
export function OSSList(params) {
    return request({
      url: '/api/system/tenant/oss/config/list',
      params
    })
  }

// 新增/编辑oss对象储存
export function updateOSS(data) {
    return request({
      url: '/api/system/tenant/oss/config/saveOrUpdate',
      method:'PUT',
      data
    })
  }

// oss对象储存详情
export function OSSDetail(ossId) {
    return request({
      url: `/api/system/tenant/oss/config/getDetail?ossId=${ossId}`,
    })
  }

// 删除oss对象储存
export function delOSS(ossIds) {
    return request({
      url: '/api/system/tenant/oss/config/remove',
      method:'DELETE',
      data:ossIds
    })
  }

// 启用禁用oss对象储存
export function isStopOSS(ossIds) {
  return request({
    url: '/api/system/tenant/oss/config/isStop',
    method:'POST',
    data:ossIds
  })
}
