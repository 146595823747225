import request from '@/utils/request'

//更新门店盘点单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/shop/bill/inventory/loss/updateBillStatus',
    method: 'post',
    data
  })
}

// 门店盘点单据分页列表
export function listOrder (query) {
  return request({
    url: '/api/system/shop/bill/inventory/loss/list',
    method: 'get',
    params: query
  })
}

//获取门店盘点单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/shop/bill/inventory/loss/getDetail?billId=${billId}`
  })
}

//新增门店盘点单据
export function addOrder (data) {
  return request({
    url: '/api/system/shop/bill/inventory/loss/save',
    method: 'POST',
    data
  })
}

//修改门店盘点单据
export function updateOrder (data) {
  return request({
    url: '/api/system/shop/bill/inventory/loss/update',
    method: 'PUT',
    data
  })
}

// 复制门店盘亏
export function copyShopInventoryLossOrder(data) {
  return request({
    url: '/api/system/shop/bill/inventory/loss/copyShopInventoryLossOrder',
    method: 'POST',
    data
  })
}
