import request from '@/utils/request'
//获取配置门店要货参数  分页列表
export function shopParamListAPI (params) {
  return request({
    url: '/api/system/shop/config/getShopRequireGoodsConfig',
    method: 'get',
    params
  })
}


// 新增/更新配置门店要货参数
export function shopParamAddOrUpdateAPI (data) {
  return request({
    url: '/api/system/shop/config/saveShopRequireGoodsConfig',
    method: 'post',
    data
  })
}

