import request from '@/utils/request'

// 查询操作日志列表
export function list(query) {
  return request({
    url: '/api/system/monitor/sys/log/list',
    method: 'get',
    params: query
  })
}

// 删除操作日志
export function delSyslog(data) {
  return request({
    url: '/api/system/monitor/sys/log/delete',
    method: 'delete',
    data
  })
}

// 清空操作日志
export function cleanSyslog(data) {
  return request({
    url: '/api/system/monitor/sys/log/clean',
    method: 'delete',
    data
  })
}

// 查询操作日志列表（商户）
export function listTenantPage(query) {
  return request({
    url: '/api/system/monitor/sys/log/listTenantPage',
    method: 'get',
    params: query
  })
}

// 清空操作日志（商户）
export function deleteByTenantId() {
  return request({
    url: '/api/system/monitor/sys/log/deleteByTenantId',
    method: 'delete'
  })
}