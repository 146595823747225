import request from '@/utils/request'

//门店业绩   分页列表
export function saleTargetListAPI (params) {
  return request({
    url: '/api/system/shop/base/month/list',
    method: 'get',
    params
  })
}
// 门店业绩  新增\修改
export function saleTargetSaveOrUpdateAPI (data) {
  return request({
    url: '/api/system/shop/base/month/saveOrUpdate',
    method: 'post',
    data
  })
}
//门店业绩  详情
export function saleTargetDetailAPI (params) {
  return request({
    url: '/api/system/shop/base/month/getDetail',
    method: 'get',
    params
  })
}
