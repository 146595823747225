import request from '@/utils/request'
// 商户数据


//交易汇总
export function merchantList(query) {
  return request({
    url: "/api/system/tenant/merchant/merchantData",
    method: "get",
    params: query,
  });
}

//交易汇总(总汇)
export function merchantTotal(query) {
  return request({
    url: "/api/system/tenant/merchant/merchantDataSummary",
    method: "get",
    params: query,
  });
}

// 商户排行
export function tenantRank(query) {
    return request({
      url: "/api/system/tenant/merchant/shopRanking",
      method: "get",
      params: query,
    });
  }

// 商户排行(总汇)
export function tenantRankTotal(query) {
  return request({
    url: "/api/system/tenant/merchant/shopRankingSummary",
    method: "get",
    params: query,
  });
}

// 门店交互汇总
export function shopTransactionSummary(query) {
  return request({
    url: "/api/system/tenant/merchant/shopTransactionSummary",
    method: "get",
    params: query,
  });
}

// 商户交互汇总
export function tenantTransactionSummary(query) {
  return request({
    url: "/api/system/tenant/merchant/tenantTransactionSummary",
    method: "get",
    params: query,
  });
}

// 商户交易汇总
export function tenantTranToTal(query) {
  return request({
    url: "/api/system/tenant/merchant/tenantTransaction",
    method: "get",
    params: query,
  });
}

// 门店交易汇总
export function shopTranToTal(query) {
  return request({
    url: "/api/system/tenant/merchant/shopTransaction",
    method: "get",
    params: query,
  });
}

// 收银端异常
export function cashierOrderLog(params) {
  return request({
    url: "/api/system/cashier/order/log/list",
    method: "get",
    params,
  });
}

// 删除收银端异常
export function deleteCashierOrderLog(data) {
  return request({
    url: "/api/system/cashier/order/log/delete",
    method: "post",
    data,
  });
}

