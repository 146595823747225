import request from '@/utils/request'

// 查询在线用户列表
export function list(query) {
  return request({
    url: '/api/system/monitor/online/list',
    method: 'get',
    params: query
  })
}

// 强退用户
export function forceLogout(tokenId) {
  return request({
    url: '/api/system/monitor/online/' + tokenId,
    method: 'delete',
  })
}

// 批量启用用户
export function isStopApi(data) {
  return request({
    url: '/api/system/monitor/online/isStop',
    method: 'POST',
    data
  })
}

// 批量强退用户
export function forceLogoutMultiple(data) {
  return request({
    url: '/api/system/monitor/online/forceLogout',
    method: 'DELETE',
    data
  })
}
