import request from '@/utils/request'

//获取配置收银参数设置  分页列表
export function payListAPI (params) {
  return request({
    url: '/api/system/shop/config/getShopCashierConfig',
    method: 'get',
    params
  })
}

// 新增/更新配置收银参数设置
export function paySaveOrUpdateAPI (data) {
  return request({
    url: '/api/system/shop/config/saveShopCashierConfig',
    method: 'post',
    data
  })
}