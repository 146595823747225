import request from '@/utils/request'

//收银热键  分页列表
export function shopHotKeyList (params) {
  return request({
    url: '/api/system/shop/key/list',
    method: 'get',
    params
  })
}

//收银热键  新增
export function shopHotKeySave (data) {
    return request({
      url: '/api/system/shop/key/save',
      method: 'post',
      data
    })
}

//收银热键  修改
export function shopHotKeyUpdate (data) {
    return request({
      url: '/api/system/shop/key/update',
      method: 'post',
      data
    })
}

//收银热键  删除
export function shopHotKeyRemove (data) {
    return request({
      url: '/api/system/shop/key/remove',
      method: 'delete',
      data
    })
}