import request from '@/utils/request'

// 产品分货分页列表
export function storeDistributeListAPI(params) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/list',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 新增产品分货
export function storeDistributeAddAPI(data) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/save',
    method: 'post',
    data
  })
}
//获取产品分货详情
export function storeDistributeDetailAPI(billId) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 修改产品分货
export function storeDistributeUpdateAPI(data) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/update',
    method: 'put',
    data
  })
}
// 更改状态
export function storeDistributeUpdateStatusAPI(data) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/updateStatus',
    method: 'post',
    data
  })
}
// 查询源单数据
export function getSourceOrderData(data) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/getSourceOrderData',
    method: 'post',
    data
  })
}
// 查询源单明细数据
export function getSourceOrderDetailData(data) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/getSourceOrderDetailData',
    method: 'post',
    timeout: 30000,
    data
  })
}

//获取成品仓、半成品仓、原料仓
export function getGoodsStoreAPI(params) {
  return request({
    url: '/api/system/produce/bill/base/getStore',
    method: 'get',
    params
  })
}

// 查询库存数量
export function findStockQtyAPI(data) {
  return request({
    url: '/api/system/produce/bill/base/findStockQty',
    method: 'post',
    data
  })
}

//    分货、新增
export function sortGoods(data) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/sortGoods',
    method: 'post',
    timeout: 60000 * 5,
    data
  })
}


// 新增生产其他订货单
export function otherOrderSave(data) {
  return request({
    url: '/api/system/produce/other/order/save',
    method: 'post',
    data
  })
}

// 生产其他订货单更改状态
export function otherOrderUpdateBillStatus(data) {
  return request({
    url: '/api/system/produce/other/order/updateBillStatus',
    method: 'post',
    data
  })
}

// 修改其他订货单
export function otherOrderUpdate(data) {
  return request({
    url: '/api/system/produce/other/order/update',
    method: 'put',
    data
  })
}

// 其他订货单列表
export function otherOrderList(params) {
  return request({
    url: '/api/system/produce/other/order/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//获取其他订货单详情
export function otherOrderGetDetail(billId) {
  return request({
    url: '/api/system/produce/other/order/getDetail?billId=' + billId,
    method: 'get'
  })
}

/// ==========

// 添加其他原料订货
export function materialOrderSave(data) {
  return request({
    url: '/api/system/produce/bill/material/order/save',
    method: 'post',
    data
  })
}

// 更新其他原料订货状态
export function materialOrderUpdateBillStatus(data) {
  return request({
    url: '/api/system/produce/bill/material/order/updateBillStatus',
    method: 'post',
    data
  })
}

// 修改其他原料订货
export function materialOrderUpdate(data) {
  return request({
    url: '/api/system/produce/bill/material/order/update',
    method: 'put',
    data
  })
}

// 其他原料订货分页列表
export function materialOrderList(params) {
  return request({
    url: '/api/system/produce/bill/material/order/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//获取其他原料订货详细信息
export function materialOrderGetDetail(billId) {
  return request({
    url: '/api/system/produce/bill/material/order/getDetail?billId=' + billId,
    method: 'get'
  })
}

//  分货单相关单据列表
export function listImportBillInfo(billId) {
  return request({
    url: '/api/system/produce/bill/goods/distribute/listImportBillInfo',
    method: 'post',
    data: { billId }
  })
}
