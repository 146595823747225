import request from '@/utils/request'

// 查询系统字典数据列表
export function listSystemData(query) {
  return request({
    url: '/api/system/dict/data/listSystemDictData',
    method: 'get',
    params: query
  })
}


// 查询【业务字典】数据列表
export function listBusinessData(query) {
  return request({
    url: '/api/system/dict/data/listBusinessDictData',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictCode) {
  return request({
    url: '/api/system/dict/data/' + dictCode,
    method: 'get'
  })
}

// 根据字典类型查询【系统字典】数据信息
export function getDicts(dictType) {
  return request({
    url: '/api/system/dict/data/system/' + dictType,
    method: 'get'
  })
}

// 新增字典数据
export function addData(data) {
  return request({
    url: '/api/system/dict/data',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data) {
  return request({
    url: '/api/system/dict/data',
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData(data) {
  return request({
    url: '/api/system/dict/data/delete',
    method: 'delete',
    data
  })
}
