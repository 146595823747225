import request from '@/utils/request'

//导购员提成方案   分页列表
export function commissionListAPI (params) {
  return request({
    url: '/api/system/shop/guideMan/commission/list',
    method: 'get',
    params
  })
}
// 导购员提成方案  新增
export function commissionAddAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/commission/save',
    method: 'post',
    data
  })
}
//导购员提成方案  详情
export function commissionDetailAPI (billId) {
  return request({
    url: '/api/system/shop/guideMan/commission/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 导购员提成方案   修改
export function commissionUpdateAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/commission/update',
    method: 'put',
    data
  })
}

// 导购员提成方案    修改状态
export function commissionUpdateStatusAPI (data) {
  return request({
    url: '/api/system/shop/guideMan/commission/updateBillStatus',
    method: 'POST',
    data
  })
}
