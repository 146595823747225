import request from '@/utils/request'

//短信商户账本和明细分页列表   分页列表
export function listStatisticsAPI (params) {
  return request({
    url: '/api/system/marketing/sms/book/listStatistics',
    method: 'get',
    params
  })
}
//短信商户账本和明细
export function listWithDetailAPI (params) {
  return request({
    url: '/api/system/marketing/sms/book/listWithDetail',
    method: 'get',
    params
  })
}
