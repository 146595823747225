import request from '@/utils/request'

//门店收款查询   分页列表
export function gatherListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/pay/shopPayModeList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店收款查询   合计行
export function gatherListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/pay/shopPayModeListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店收款明细查询   分页列表
export function gatherDetailListAPI(params) {
  return request({
    url: '/api/system/shop/report/sale/pay/shopPayModeListDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//按小票查询明细   分页列表
// export function billDetailListAPI (billNo) {
//   return request({
//     url: '/api/system/shop/report/sale/summary/listBillNoDetail?billNo='+billNo,
//     method: 'get',
//   })
// }
