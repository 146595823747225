import request from '@/utils/request'

//销售明细报表   分页列表
export function saleDetailListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/saleDetail',
    method: 'get',
    params
  })
}
//销售明细报表   分页列表合计
export function sumSaleDetailListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/sumSaleDetail',
    method: 'get',
    params
  })
}
//销售汇总报表   分页列表
export function saleSummaryListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/saleSummary',
    method: 'get',
    params
  })
}
//销售汇总报表   分页列表合计
export function sumSaleSummaryListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/sumSaleSummary',
    method: 'get',
    params
  })
}
//销售订单汇总报表   分页列表
export function saleOrderSummaryListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/saleOrderSummary',
    method: 'get',
    params
  })
}
//销售订单汇总报表   分页列表合计
export function sumSaleOrderSummaryListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/sumSaleOrderSummary',
    method: 'get',
    params
  })
}
//销售排行   分页列表
export function saleRankListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/saleRanking',
    method: 'get',
    params
  })
}
//销售排行   分页列表合计
export function sumSaleRankListAPI (params) {
  return request({
    url: '/api/system/sale/reportForms/sumSaleRanking',
    method: 'get',
    params
  })
}
